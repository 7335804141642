.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.verifiedModelContainer {
    display: flex;
    flex-direction: row;
    gap: 14px;
}

.verifiedTextContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    width: 100%;
}

.verifiedTextValue {
    font-weight: 700;
    font-size: 30px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 10px;
}

.verifiedTextDescription {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
}
