.pageContainer {
    background: var(--adfi_dark10);
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    height: 100%;
}

.pageContainer li {
    font-size: 16px;
    margin-left: 20px;
}

.header {
    text-align: left;
}

.contentContainer {
    max-width: 550px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.questionHeader {
    font-size: 16px;
    font-weight: normal;
}

.pricingHeader {
    font-size: 20px;
    font-weight: 700;
    color: #5b2ac3;
}

.pricingModelType {
    font-size: 16px;
    width: 200px;
    text-align: left;
}

.clienteleBox {
    border-radius: 6px;
    padding: 5px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    border: 1px solid var(--adfi_blue300);
    color: var(--adfi_blue500);
}

.comboInputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    margin-bottom: 20px;
    text-align: left;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
}

.pricingModelInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.additionalInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;
    margin-left: 10px;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
}

.checkCircle {
    background-color: var(--adfi_green500);
    height: 36px;
    min-width: 36px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.checkCircleUnclicked {
    border: 1px solid #81808d;
    height: 36px;
    min-width: 36px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.sectionContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: left;
    background: white;
    padding: 10px;
    padding-left: 20px;
    align-items: center;
    gap: 6px;
    border-radius: 10px;
}

.inputLabel {
    font-weight: 700;
    font-size: 16px;
}

.input {
    width: fit-content !important;
}

@media only screen and (max-width: 1042px) {
    .pageContainer {
        padding: 100px 20px 20px 20px;
    }

    .header {
        font-size: 26px;
    }
}

@media only screen and (max-width: 800px) {
    .checkboxContainer {
        display: inline-grid;
        grid-template-columns: 2fr 2fr;
    }
}
