.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 2000;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
}

// @keyframes animation {
//   from {
//     bottom: -2000px
//   }
//   to {
//     bottom: 70px
//   }
// }

// .modal {
//   background: white;
//   padding: 20px;
//   z-index: 2000;
//   border-radius: 10px;
//   position: fixed;
//   bottom: 70px;
//   left: 50%;
//   transform: translateX(-50%);
//   background-color: rgba(0, 0, 0, 0.5);
//   backdrop-filter: blur(3px);

//   animation: 1s animation;
// }

.modal.show {
    opacity: 1;
    pointer-events: visible;
}

.modal.enter-done {
    opacity: 1;
    pointer-events: visible;
}

.modal.exit {
    opacity: 0;
}

.modal-content {
    width: 100%;
    max-width: 640px;
    max-height: 90%;
    overflow: auto;
    background: var(--adfi_white);
    box-shadow: 0px 8px 30px rgba(45, 43, 65, 0.4);
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    transform: translateY(0px);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    padding-right: 30px;
}

.modal-content-large {
    width: 100%;
    max-width: 768px;
    max-height: 90%;
    overflow: auto;
    background: var(--adfi_white);
    box-shadow: 0px 8px 30px rgba(45, 43, 65, 0.4);
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    transform: translateY(0px);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    padding-right: 30px;
}

.no-padding {
    padding: 0;
}

.modal.enter-done .modal-content {
    transform: translateY(0);
}

.modal.exit .modal-content {
    transform: translateY(-200px);
}

.modal-footer {
    border-top: 1px solid var(--adfi_dark50);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    gap: 10px;
}

.modal-cancel-button {
    height: 48px;
}

.modal-close-icon {
    position: absolute;
    right: 21px;
    top: 21px;
    cursor: pointer;
}

.modal-title {
    padding-top: 32px;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    color: var(--adfi_dark500);
    margin-bottom: 10px;
}

.modal-body {
    width: 100%;
}
