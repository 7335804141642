.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.modelContainer {
    display: flex;
    flex-direction: row;
    gap: 14px;
}

.titleText {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    display: flex;
}

.textContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    width: 100%;
}

.textValue {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.welcomeImgDesktop {
    height: 281px;
    width: 558;
    max-width: 100%;
    max-height: 100%;
    border: 4px solid #e9eaf5;
    border-radius: 4px;
}

.welcomeImgMobile {
    height: fit-content;
    width: 558;
    max-width: 100%;
    max-height: 281px;
    border: 4px solid #e9eaf5;
    border-radius: 4px;
}

.welcomeImgDesktop img {
    max-height: 100%;
}

.welcomeImgMobile img {
    max-height: 100%;
}

.buttonText {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    width: 200px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    // align-items: center;
    height: 55px;
    justify-content: space-between;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .buttonContainer {
        flex-direction: column-reverse;
        height: auto;
        justify-content: unset;
        width: auto;
        gap: 30px;
    }
}
