.formContainer {
    padding: 10px 0px 0px 0px;
}

.formSection {
    padding: 0px 25px 0px 25px;
    margin-bottom: 15px;
}

.errorSection {
    padding: 0px 25px 0px 25px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerContainer {
    background-color: #f2f3fb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.checkboxContainer {
    margin: 5px;
}

/* Hide the browser's default checkbox */
.optionRow input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    display: inline-block;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: 1px solid var(--adfi_dark200);
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.optionRow:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.optionRow input:checked ~ .checkmark {
    background-color: rgba(113, 52, 243, 1);
    border: 1px solid var(--adfi_blue500);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.optionRow input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.optionRow .checkmark:after {
    margin-left: 4px;
    margin-top: 0px;
    width: 5px;
    height: 10px;
    border: 1.67px solid var(--adfi_white);
    border-width: 0 1.67px 1.67px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
