.pageContainer {
    background: var(--adfi_dark10);
    min-height: 100vh;
}

.sideNavMargin {
    padding-left: 0px;
}

@media only screen and (min-width: 1200px) {
    .sideNavMargin {
        padding-left: 70px;
    }
}

.settingsHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 25px;
    margin-left: 35px;
    margin-bottom: 29px;
}

.settingsHeaderText {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: var(--adfi_dark500);
    margin-left: 14px;
    margin-right: 33px;
}

.settingContainer {
    display: flex;
    flex-direction: row;
    padding-bottom: 56px;
    margin-left: 35px;
}

.leftMenuContainer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 14px;
    background-color: #fff;
    border-radius: 8px;
    list-style-type: none;
    text-align: left;
    margin-right: 10px;
    width: 25%;
    height: fit-content;
}

.menuOption {
    padding: 14px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}

.menuOption:hover {
    background: var(--adfi_blue100_30percent);
    border-radius: 6px;
    color: #7134f3;
}

.active {
    background: var(--adfi_blue100_30percent);
    border-radius: 6px;
    color: #7134f3;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 20px;
    background: var(--adfi_white);
    border-radius: 8px;
    margin-right: 30px;
    flex-grow: 1;
    height: 100vh;
}

.infoHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #e9eaf5;
    gap: 20px;
    padding-bottom: 20px;
}

.accountInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.accountInfoRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #e9eaf5;
    text-align: left;
}

.accountInfoValue {
    margin-left: auto;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.calendlyForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: auto;
    margin-top: 40px;
    gap: 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: 75%;
}

.purpleText {
    color: var(--adfi_blue500);
    align-items: center;
    cursor: pointer;
}

.rightSideButtons {
    margin-left: auto;
    margin-right: 30px;
    display: flex;
    gap: 16px;
}

.editDisclosure {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 20px 0px;
    margin-left: 35px;
    margin-right: 30px;
    margin-bottom: 30px;
    gap: 10px;
    height: 66px;

    background: var(--adfi_blue50);
    border-radius: 8px;

    font-weight: 700;
    font-size: 1.25vw;
    line-height: 26px;
    color: var(--adfi_blue500);
}

.preivewButton {
    margin-top: 10px;
}

@media screen and (max-width: 1040px) {
    .settingContainer {
        flex-direction: column;
        width: 100vw;
        gap: 1px;
    }

    .settingsHeader {
        margin-left: 20px;
    }

    .settingsHeaderText {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
    }

    .settingContainer {
        flex-direction: column;
        padding-bottom: 56px;
        margin-left: 0px;
    }

    .leftMenuContainer {
        display: flex;
        flex-direction: row;
        padding: 16px;
        gap: 14px;
        background-color: #fff;
        border-radius: 0px;
        list-style-type: none;
        text-align: left;
        margin-right: 0px;
        width: 100%;
        overflow-x: scroll;
    }

    .infoContainer {
        width: 100%;
        margin: 0;
        border-radius: 0px;
    }

    .calendlyForm {
        width: 100%;
    }

    .editDisclosure {
        padding: 20px 10px 20px 10px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 2.54vw;
        margin-bottom: 0px;
    }
}
