.bannerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 10px;
    background: var(--adfi_white);
    border: 1px solid var(--adfi_red500);
    box-shadow: 0px 3px 6px rgba(171, 170, 179, 0.3);
    border-radius: 10px;
    margin-top: auto;
    width: fit-content;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.bannerContainerAbsolute {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 10px;
    background: var(--adfi_white);
    border: 1px solid var(--adfi_red500);
    box-shadow: 0px 3px 6px rgba(171, 170, 179, 0.3);
    border-radius: 10px;
    margin-top: auto;
    position: absolute;
    bottom: 50px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.triange {
    height: 36px;
    width: 36px;
    padding: 10px;
    background: #ffecea;
    border-radius: 6px;
}

.bannerX {
    cursor: pointer;
}
