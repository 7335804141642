/* body {
  margin: 0;
  font-family: "Assistant", sans-serif;
  color: var(--adfi_dark500);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    margin: 0;
    font-family: "Assistant", sans-serif;
    color: var(--adfi_dark500);
}
