.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
    padding: 10px 30px 10px 30px;
}

ol {
    margin-left: 5px;
}

ol li {
    list-style-position: inside;
}

.infoTextArea {
    width: 100%;
    height: 100px;
    padding: 8px 10px;
    box-sizing: border-box;
    border: 1px solid var(--adfi_dark300);
    border-radius: 4px;
    background-color: #ffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    resize: none;
}

.infoTextArea:focus {
    outline: none;
    border: 1px solid var(--adfi_blue300);
}

.input {
    border: 1px solid var(--adfi_dark300);
    border-radius: 6px;
    padding: 15px;
    width: 100%;
}

.input:focus {
    outline: none;
    border: 1px solid var(--adfi_blue300);
}

.formFooter {
    background-color: var(--adfi_dark50);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 30px;
}

.formContainerCompleted {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    padding: 10px 30px 10px 30px;
    text-align: center;
    min-height: 400px;
}

.completeImg {
    max-width: 400px;
    height: auto;
}
