.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 40px;
}

.pricingModelContainer {
    display: flex;
    flex-direction: row;
    gap: 14px;
}

.checkCircle {
    background-color: var(--adfi_green500);
    height: 36px;
    min-width: 36px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.pricingTextContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--adfi_dark100);
    width: 100%;
}

.pricingTextValue {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

.pricingTextDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.bold {
    font-weight: 700;
}
