.pageContainer {
    background: var(--adfi_dark10);
    min-height: 100vh;
}

.sideNavMargin {
    padding-left: 0px;
}

@media only screen and (min-width: 1200px) {
    .sideNavMargin {
        padding-left: 70px;
    }
}

.adminHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 25px;
    margin-left: 35px;
    margin-bottom: 29px;
}

.adminHeaderText {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: var(--adfi_dark500);
    margin-left: 14px;
    margin-right: 33px;
}

.applicationListContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Advisor Finder/White */
    border-radius: 8px 8px 0px 0px;
    gap: 10px;
    margin-left: 35px;
    // padding-top: 20px;
    flex-grow: 1;
}

.applicationContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px;
    /* Advisor Finder/White */
    border-radius: 8px 8px 8px 8px;
    background: var(--adfi_white);
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 10px;

    width: 50%;
}

.verifiedAdvisorContainer {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 10px;
    /* Advisor Finder/White */
    border-radius: 8px 8px 8px 8px;
    background: var(--adfi_white);
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 10px;
    width: 25%;
}

.verifiedAdvisorsContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 30px;
    align-items: flex-start;
}

.profileInfoContainer {
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    text-align: left;
    margin-left: 20px;
    margin-right: 16px;
}

.appButtons {
    margin-left: auto;
    display: flex;
    gap: 6px;
}

.locationText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    word-break: break-all;
    /* Advisor Finder/Dark/500 */
    color: var(--adfi_dark500);
    display: inline;
}

.advisorNameText {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: var(--adfi_dark500);
    margin-top: 2px;
}

.jobTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.employerText {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.optionRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    flex: 1 0 25%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_dark500);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.optionRow input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    display: inline-block;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: 1px solid var(--adfi_dark200);
    border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.optionRow input:checked ~ .checkmark {
    background-color: rgba(113, 52, 243, 1);
    border: 1px solid var(--adfi_blue500);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.optionRow input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.optionRow .checkmark:after {
    margin-left: 4px;
    margin-top: 0px;
    width: 5px;
    height: 10px;
    border: 1.67px solid var(--adfi_white);
    border-width: 0 1.67px 1.67px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.paidContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

@media screen and (max-width: 1040px) {
    .adminHeader {
        margin-left: 20px;
    }

    .adminHeaderText {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
    }

    .applicationListContainer {
        margin-left: 20px;
    }

    .applicationContainer {
        width: 95%;
    }

    .verifiedAdvisorContainer {
        width: 50%;
    }
}
