.backgroundViewHeight {
    background-color: var(--adfi_dark10);
    height: 100vh;
}
.pageContainer {
    background-color: var(--adfi_dark10);
    display: flex;
    justify-content: center;
    margin: 0px 0px 0px 70px;
    padding-left: 20px;
}

.contentContainer {
    width: 100%;
    max-width: 90%;
    max-width: 1800px;
}

.swiperWithButtons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiperButtonAligner {
    padding: 30px;
}

.mySwiper {
    margin-bottom: 20px;
    padding: 10px;
    height: 100%;
    width: 100%;
}

.assessmentResultsHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 20px;
    margin-right: 30px;
}

.assessmentResultsHeader h2 {
    font-weight: 400;
    font-size: 16px;
}

.mobileSlide {
    justify-content: center;
}

.updateAssessmentButton {
    max-width: fit-content;
    float: right;
    height: fit-content;
    margin-bottom: 10px;
}

.assessmentCardContainer {
    width: 100%;
    height: fit-content;
    background-color: white;
    border-radius: 8px;
    box-shadow:
        1px 2px 2px hsl(220deg 20% 85% / 0.333),
        2px 4px 4px hsl(220deg 20% 85% / 0.333),
        3px 6px 6px hsl(220deg 20% 85% / 0.333);
    outline: 1px solid #d5d5d9;
    /* <- here */
    transition: outline 0.3s;
    text-decoration: none;
    /* I added this for clarity of effect */
    height: 660px;
    cursor: pointer;
}

.assessmentCardTopInfo {
    width: 100%;
    height: 50px;
    border-radius: 8px 8px 0px 0px;
    background: #e9eaf5;
}

.assessmentCardContainer:hover {
    outline: 1px solid var(--adfi_blue300);
}

.assessmentCardImgContainer {
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    height: 150px;
    background: #e9eaf5;
}

.assessmentCardImgContainerBottom {
    width: 380px;
    height: 100px;
}

.profilePicture {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border: double 3px transparent;
    border-radius: 50%;
    background-color: white;
    border-color: rgba(198, 174, 250, 1);
}

.assessmentCardContainer:hover .profilePicture {
    border: double 3px transparent;
    border-radius: 50%;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, rgba(115, 21, 249, 1), rgba(216, 26, 250, 1));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.assessmentCardContainer:hover .assessmentCardImgContainer {
    background: linear-gradient(56deg, #7315f9 1.98%, #d81afa 78.61%);
}

.locationContainer {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 4px;
    border-radius: 4px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    color: white;
    font-size: 14px;
    gap: 5px;
}

.favoriteContainer {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 10px;
    border-radius: 50%;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
}

.favoriteContainerEnabled {
    background-color: gold;
}

.assessmentCardContentContainer {
    display: flex;
    justify-content: center;
    align-items: left;
    text-align: left;
    flex-direction: column;
    padding: 15px;
    gap: 10px;
}

.isInterestedStageOne {
    background-color: #f2f3fb;
}

.isInterestedStageTwo {
    background-color: #f2f3fb;
    opacity: 70%;
    cursor: none;
}

.shareDetailsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--adfi_dark10);
    border-radius: 8px;
    padding: 6px;
    gap: 5px;
}

.assessmentCardContentContainer h1 {
    font-size: 24px;
}

.assessmentCardContentContainer h2 {
    font-weight: normal;
    font-size: 16px;
}

.tagsHeader {
    text-align: left;
    font-size: 15px;
    margin-bottom: 5px;
    margin-left: 5px;
}

.tagsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

.tag {
    border-radius: 6px;
    padding: 1px 5px;
    width: fit-content;
    font-size: 14px;
    border: 1px solid #dbcbff;
    color: #441f92;
    background-color: #f5f0ff;
}

.footerContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.footerStat {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis "[]";
    display: inline-block;
}

.spacingDots {
    color: #d5d5d9;
}

.marketplaceFooter {
    background-color: #e9eaf5;
    outline: 1px solid #e3d6fd;
    /* <- here */
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    margin-right: 30px;
    border-radius: 20px;
}

.marketplaceFooterMobile {
    background-color: #e9eaf5;
    outline: 1px solid #e3d6fd;
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
    flex-direction: column;
    height: 150px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    padding: 10px 30px 10px 30px;
}

.infoTextContainer {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.profilePictureMini {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: double 3px transparent;
    border-radius: 50%;
    background-color: white;
    border-color: rgba(198, 174, 250, 1);
}

.miniAdvisorProfile {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    gap: 20px;
    border-radius: 8px;
    border: 1px solid var(--adfi_dark50);
}

.mobileSwiperButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.viewProfileSubPicture {
    display: none;
}

.assessmentCardContainer:hover .viewProfileSubPicture {
    background-color: var(--adfi_blue500);
    color: white;
    border-radius: 20px;
    margin-top: -20px;
    gap: 5px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 5px 10px;
}

@media only screen and (max-width: 1300px) {
    .pageContainer {
        margin: 0px;
        padding: 0px;
    }

    .contentContainer {
        margin-right: 0px 0px 0px 15px;
    }

    .assessmentResultsHeader {
        margin-right: 15px;
    }

    .marketplaceFooter {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 1041px) {
    .contentContainer {
        padding-top: 90px;
    }
}

@media only screen and (max-width: 800px) {
    .pageContainer {
        margin: 0px;
        padding: 0px;
    }
    .assessmentCardContainer {
        width: 100%;
    }

    .assessmentCardImgContainer {
        width: 100%;
    }

    .marketplaceFooter {
        flex-direction: column;
        gap: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .pageContainer {
        margin: 0px;
        padding: 0px;
    }
}
