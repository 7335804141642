.pagination {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    font-size: 1.2rem;
    gap: 5px;
    flex-wrap: wrap;
}

.pagination .page-num {
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 6px;
    color: black;
}

.active .page-num {
    background-color: var(--adfi_blue600);
    color: white;
}
