.intoFormContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.bannerContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 52px;
    background: var(--adfi_green50);
    border-radius: 6px;
    padding-left: 18px;
}

.bannerText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.introForm {
    width: 100%;
    margin-bottom: 30px;
}

.infoTextArea {
    width: 100%;
    height: 238px;
    padding: 11px 16px;
    box-sizing: border-box;
    border: 1px solid var(--adfi_dark300);
    border-radius: 4px;
    background-color: #ffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    resize: none;
}

.characterCount {
    color: var(--adfi_dark300);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
}

.modal-footer {
    border-top: 1px solid var(--adfi_dark50);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;
}

.modal-cancel-button {
    height: 48px;
}
