.formContainer {
    padding: 10px 0px 0px 0px;
}

.formSection {
    padding: 0px 25px 0px 25px;
    margin-bottom: 15px;
}

.greyLineDivider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--adfi_dark200);
    opacity: 50%;
    margin-bottom: 15px;
}
.filtersHeader {
    padding-left: 20px;
}
// States //
.stateContentContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.verticaloffsetmedium {
    margin-bottom: 15px;
}

.filtersDropdown {
    width: 238px;
    height: 48px;
    border-radius: 8px;
    padding-left: 15px;
    border: 2px solid var(--adfi_dark200);

    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.95rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
}

.filtersDropdown:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}

.filtersDropdown:focus {
    outline: none;
    border: 2px solid var(--adfi_blue300);
}

.filtersDropdown::selection {
    margin-left: 5px;
    color: var(--adfi_dark100);
}

@media only screen and (max-width: 800px) {
    .stateContentContainer {
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }
}

// Account size //

.accountSizeGreyBox {
    height: 110px;
    width: 100%;
    background-color: #f2f3fb;
    display: inline-grid;
    grid-template-columns: 2fr 1fr;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.accountSizeBackgroundBar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 428px;
    height: 16px;
    border-radius: 8px;
    background-color: white;
    padding-left: 2px;
    padding-right: 2px;
}

.accountSizeStartSection {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue100);
    border-radius: 8px 0px 0px 8px;
}

.accountSizeStartSection:hover {
    background-color: var(--adfi_blue200);
}

.accountSizeSection {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue100);
}

.accountSizeSection:hover {
    background-color: var(--adfi_blue200);
}

.accountSizeEndSection {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue100);
    border-radius: 0px 8px 8px 0px;
}

.accountSizeEndSection:hover {
    background-color: var(--adfi_blue200);
}

.accountSizeStartSectionSelected {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue400);
    border-radius: 8px 0px 0px 8px;
}

.accountSizeSectionSelected {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue400);
}

.accountSizeEndSectionSelected {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue400);
    border-radius: 0px 8px 8px 0px;
}

.assetRangeTextBox {
    border: 2px solid #81808d;
    border-radius: 6px;
    padding: 10px;
    background-color: white;
}

.assetRangeDashContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 428px;
    height: 16px;
    padding-left: 2px;
    padding-right: 2px;
    margin-left: 2px;
}

.assetRangeNumericalContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 428px;
    gap: 3px;
    height: 12px;
    text-align: center;
}

.rangeIndicator {
    width: 50px;
    height: 10px;
    border-right: 0.5px solid grey;
}

.blankRangeIndicator {
    width: 50px;
    height: 10px;
}

.assetRangeNumber {
    width: 50px;
    text-align: center;
}

.mobileAccountSizeDropdown {
    display: none;
}

@media only screen and (max-width: 800px) {
    .accountSizeGreyBox {
        height: 162px;
        width: 100%;
        background-color: #f2f3fb;
        display: inline-grid;
        grid-template-columns: 1fr;
        justify-content: space-evenly;
        align-items: center;
    }

    .accountRangeContainer {
        width: 303px;
    }

    .accountSizeBackgroundBar {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 303px;
        height: 20px;
        border-radius: 8px;
        gap: 2px;
    }

    .accountSizeSection {
        width: 37px;
        height: 15px;
    }
    .accountSizeStartSection {
        width: 37px;
        height: 15px;
    }
    .accountSizeEndSection {
        width: 37px;
        height: 15px;
    }

    .accountSizeStartSectionSelected {
        width: 37px;
        height: 15px;
    }

    .accountSizeSectionSelected {
        width: 37px;
        height: 15px;
    }

    .accountSizeEndSectionSelected {
        width: 37px;
        height: 15px;
    }

    .assetRangeDashContainer {
        width: 303px;
        height: 16px;
        padding-left: 2px;
        padding-right: 2px;
        gap: 1px;
        margin-left: 2px;
    }

    .assetRangeNumericalContainer {
        width: 303px;
        height: 12px;
        text-align: center;
    }

    .assetRangeNumber {
        width: 35px;
        font-size: 13px;
        font-style: bold;
        text-align: center;
    }

    .assetRangeTextBox {
        text-align: center;
    }
}

// Industry Type

.industryTypesContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
}

.industryType {
    cursor: pointer;
    width: 220px;
    height: 56px;
    border: 2px solid var(--adfi_dark100);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
    font-size: 18px;
}

.industryType:hover {
    border: 2px solid var(--adfi_blue200);
}

.industryTypeSelected {
    cursor: pointer;
    width: 220px;
    height: 56px;
    border: 2px solid var(--adfi_blue400);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
    font-size: 18px;
}

//Help With

.helpWithContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
}

.helpWith {
    cursor: pointer;
    width: 220px;
    height: 150px;
    border: 2px solid var(--adfi_dark100);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    font-size: 15px;
    transition: scale(1.5);
}

.helpWith:hover {
    border: 2px solid var(--adfi_blue200);
}

.helpWithSelected {
    cursor: pointer;
    width: 220px;
    height: 150px;
    border: 2px solid var(--adfi_blue400);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    font-size: 15px;
}

.helpWithImg {
    max-width: 120px;
    max-height: 100px;
    padding: 10px;
}

@media only screen and (max-width: 800px) {
    .industryType {
        width: 150px;
        height: 44px;
    }

    .industryTypeSelected {
        width: 150px;
        height: 44px;
    }
}

@media only screen and (max-width: 500px) {
    .helpWith {
        width: 130px;
        height: 120px;
    }

    .helpWithSelected {
        width: 130px;
        height: 120px;
    }

    .helpWithImg {
        max-width: 80px;
        max-height: 60px;
        padding: 5px;
    }
}

@media only screen and (max-width: 400px) {
    .accountSizeGreyBox {
        display: none;
    }

    .mobileAccountSizeDropdown {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .industryTypeSelected {
        width: 135px;
        height: 44px;
        font-size: 16px;
    }

    .industryType {
        width: 135px;
        height: 44px;
        font-size: 16px;
    }
}

//Footer
.footerContainer {
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f2f3fb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
