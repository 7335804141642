/* Main */

button {
    font-family: "Assistant", sans-serif;
    font-weight: 700;
}

// Size Prop

.regular {
    padding: 12px 20px;
    height: 50px;
}

.large {
    position: relative;
    min-width: 190px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
}

.thin {
    position: relative;
    padding: 8px 16px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
}

.extraThin {
    position: relative;
    padding: 4px 16px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
}

.circleAssessment {
    border-radius: 50%;
    width: 70px;
    height: 70px;
}

@media only screen and (max-width: 500px) {
    .thin {
        width: 100%;
    }

    .shrinkText {
        font-size: 14px;
    }
}

// Display Prop

.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.buttonChildren {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

// Color Prop

.gradient-button {
    border: 1px none var(--adfi_blue500);
    border-radius: 8px;
    background-image: url("../../../public/images/CTA.jpg");
    background-position: 50% 50%;
    background-size: cover;
    backface-visibility: hidden;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
}

.gradient-button:hover:not([disabled]) {
    background-position: 10% 0%;
    background-size: auto;
    -webkit-transform: translate(0px, -2px);
    -ms-transform: translate(0px, -2px);
    transform: translate(0px, -2px);
}

.gradient-button:disabled {
    background-color: var(--adfi_blue100);
    background-image: none;
    color: var(--adfi_blue400);
    cursor: not-allowed;
}

.clear-button {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: var(--adfi_blue500);
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    border: 1px solid var(--adfi_blue500);
    background-color: transparent;
}

.square {
    border-radius: 8px;
}

.clear-button:hover:not([disabled]) {
    box-shadow: 0 4px 6px 0 rgba(113, 52, 243, 0.22);
    -webkit-transform: translate(0px, -2px);
    -ms-transform: translate(0px, -2px);
    transform: translate(0px, -2px);
    color: var(--adfi_blue500);
}

.clear-button:disabled {
    background-color: var(--adfi_blue100);
    border: none;
    cursor: not-allowed;
}

.grey-button {
    padding: 12px 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: var(--adfi_dark400);
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    background-color: transparent;
}

.no-border {
    border: none;
}

.yellow {
    background-color: #f69400;
    background-image: none;
}

.white {
    background-color: white;
    background-image: none;
}

.green {
    background-color: var(--adfi_green500);
    background-image: none;
}

.red {
    background-color: var(--adfi_red500);
    background-image: none;
}

.grey {
    background-color: var(--adfi_dark50);
    border: none;
    color: var(--adfi_dark400);
    background-image: none;
}

.box-shadow {
    box-shadow:
        1px 2px 2px hsl(220deg 20% 85% / 0.333),
        2px 4px 4px hsl(220deg 20% 85% / 0.333),
        3px 6px 6px hsl(220deg 20% 85% / 0.333);
}

.fullWidth {
    width: 100%;
}

//Split Buttons

// .splitButtonsContainer {
//     display: flex;
//     flex-wrap: nowrap;
// }

// .clear-button-split {
//     background-color: white;
//     background-image: none;
//     -webkit-box-pack: center;
//     -webkit-justify-content: center;
//     -ms-flex-pack: center;
//     align-items: center;
//     justify-content: center;
//     -webkit-transition: all 200ms ease;
//     transition: all 200ms ease;
//     color: var(--adfi_blue500);
//     font-size: 16px;
//     font-weight: 700;
//     text-decoration: none;
//     height: 50px;
//     border: 1px solid var(--adfi_blue500);
// }

// .clear-button-split:hover:not([disabled]) {
//     box-shadow: 0 4px 6px 0 rgba(113, 52, 243, 0.22);
//     -webkit-transform: translate(0px, -2px);
//     -ms-transform: translate(0px, -2px);
//     transform: translate(0px, -2px);
//     color: var(--adfi_blue500);
// }

// .clear-button-split:disabled {
//     background-color: var(--adfi_blue100);
//     border: none;
//     cursor: not-allowed;
// }

// .buttonLeftBorderRadius {
//     border-radius: 8px 0px 0px 8px;
// }

// .buttonRightBorderRadius {
//     border-radius: 0px 8px 8px 0px;
// }

// .splitButtonChildren {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
// }

// .buttonFirstHalf {
//     padding: 12px 20px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 5px;
// }

// .buttonSecondHalf {
//     padding: 12px 15px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// @media screen and (max-width: 600px) {
//     .buttonFirstHalf {
//         font-size: 14px;
//         min-width: 165px;
//     }5

//     .buttonFirstHalf svg {
//         width: 15;
//         height: 15;
//     }
// }
