.formContainer {
    display: flex;
    flex-direction: column;
    min-height: 220px;
    gap: 15px;
}

.bannerText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.inputForm {
    width: 100%;
    margin-bottom: 9px;
}

.modal-footer {
    border-top: 1px solid var(--adfi_dark50);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;
}

.removeButton {
    height: 48px;
    border: 1px solid var(--adfi_red500);
    color: var(--adfi_red500);
}

.removeButton:hover:not([disabled]) {
    color: var(--adfi_red500);
}

.modalButtons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.halfInput {
    display: flex;
    flex-direction: column;
    width: 48%;
}
