.intoFormContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
}

.bannerContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 52px;
    border-radius: 6px;
    padding-left: 18px;
}

.greenBackground {
    background: var(--adfi_green50);
}

.redBackground {
    background: var(--adfi_red50);
}

.bannerText {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
}

.modal-footer {
    border-top: 1px solid var(--adfi_dark50);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;
}

.modal-cancel-button {
    height: 48px;
}
