.notFoundPageContainer {
    display: flex;
    flex-direction: column;
    background: var(--adfi_dark10);
    width: 100vw;
    min-height: 150vh;
    align-items: center;
}

.adfiLogo {
    height: 40px;
    width: 189px;
    margin-top: 40px;
    margin-bottom: 94px;
}

.notFoundHeader {
    font-weight: 700;
    font-size: 36px;
    line-height: 47px;
}

.notFoundMessage {
    max-width: 600px;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    margin-top: 16px;
}

.notFoundImage {
    background: linear-gradient(175.33deg, #ffffff 8.2%, rgba(248, 240, 255, 0.54) 55.25%);
    box-shadow:
        inset -2.34577px -2.34577px 5px rgba(222, 204, 252, 0.9),
        inset 2.34577px 2.34577px 8.69px rgba(255, 255, 255, 0.9);
}

.returnButton {
    margin-top: 30px;
}
