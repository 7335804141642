.servicesContainer_edit {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: var(--adfi_white);
    text-align: left;
    overflow: hidden;
}

.servicesHeader_edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: var(--adfi_dark500);
    gap: 10px;
}

.servicesHeader_edit h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

.countNumber {
    background: var(--adfi_dark10);
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--adfi_dark300);
    padding: 4px 8px 4px 8px;
}

.addIcons {
    background: var(--adfi_dark10);
    border-radius: 25px;
    margin-left: auto;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
}

.showMoreArrow {
    cursor: pointer;
    transition: all 0.3s ease;

    &.open {
        transform: scaleY(-1);
    }
}

.dataContainer_edit {
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
}

.editPencil {
    position: absolute;
    right: 4.05%;
    top: 9.05%;
    bottom: 8.34%;
    cursor: pointer;
}

.clamp {
    max-height: 96px;
    row-gap: 30px;
}

.service_edit {
    position: relative;
    background: var(--adfi_white);
    border-radius: 6px;
    border: 1px solid #e9eaf5;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px 12px 20px;
    gap: 12px;
    height: 96px;
    width: 384px;
}

.service_edit:hover {
    background-position: 10% 0%;
    background-size: auto;
    -webkit-transform: translate(0px, -2px);
    -ms-transform: translate(0px, -2px);
    transform: translate(0px, -2px);
    border: 1px solid var(--adfi_blue500);
}

.pointer {
    cursor: pointer;
}

.serviceText_edit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.serviceName {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
}

.serviceImg {
    width: 96px;
    height: 100%;
    align-items: center;
    display: flex;
    max-height: 100%;
}

.serviceImg img {
    max-height: 100%;
    max-width: 100%;
}

.dragHandle {
    display: flex;
    height: 46px;
    width: 12px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    touch-action: none;
    cursor: var(--cursor, pointer);
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}

.dragHandle:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.dragHandle:focus-visible {
    box-shadow: 0 0px 0px 2px #4c9ffe;
}

.dragHandle svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: #919eab;
}

.submitOrderButton {
    padding: 3px 5px;
    font-size: 12px;
}

@media screen and (max-width: 1040px) {
    .servicesHeader_edit {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .servicesHeader_edit h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .dataContainer_edit {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 6px;
    }

    .service_edit {
        width: 100%;
        max-width: 100%;
        align-items: center;
        flex-direction: row;
    }

    .serviceText_edit {
        align-items: flex-start;
        gap: 6px;
    }

    .editPencil {
        right: 4.05%;
        top: 23.05%;
    }

    .clamp {
        max-height: 369px;
        row-gap: 6px;
    }
}
