.rsnipTextArea {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    border: 1px solid;
    padding: 10px;
    text-align: left;
}

.rsnipTextArea ul {
    padding-left: 20px;
}

.disclosureContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 25px;
}

.disclosureHeader {
    font-weight: 700;
    font-size: 16px;
}

.disclosureText {
    font-weight: 400;
    font-size: 16px;
    white-space: pre-line;
}

.rsnipDisclosureContainer {
    width: fit-content;
    padding-left: 35px;
    padding-bottom: 25px;
}
