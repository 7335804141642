.pageContainer {
    background-color: var(--adfi_dark10);
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 20px;
}

.flexContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.infoMessageContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    background: #e9eaf5;
    border-radius: 6px;
    padding: 20px;
    text-align: left;
    margin: 20px 10px 10px 10px;
    max-width: 720px;
}

.infoMessageContainer svg {
    flex-shrink: 0;
}

.skipButton {
    font-size: 16px;
    color: var(--adfi_blue300);
    cursor: pointer;
}

// PROGRESS BAR

.progressBarContainer {
    height: 4px;
    width: 100%;
    background-color: var(--adfi_dark10);
}

.progressBar {
    height: 100%;
    background: linear-gradient(271.17deg, #7315f9 0.01%, #d81afa 69.68%);
}

// HEADER

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoContainer img {
    width: 160px;
    height: 40px;
}

.emailLoginLogo img {
    width: fit-content;
    height: fit-content;
}

.headerAlignmentContainer {
    width: 160px;
}

.outerCircle {
    background: var(--adfi_blue100);
    border-radius: 50%;
    height: 22px;
    width: 22px;
    position: relative;
}

.middleCircle {
    position: absolute;
    background: white;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    top: 50%;
    left: 50%;
    margin: -8px 0px 0px -8px;
}

.middleCircleIcon {
    position: absolute;
    color: var(--adfi_blue500);
    border-radius: 50%;
    height: 16px;
    width: 16px;
    top: 50%;
    left: 50%;
    margin: -8px 0px 0px -8px;
}

.innerCircle {
    position: absolute;
    background: var(--adfi_blue400);
    border-radius: 50%;
    height: 8px;
    width: 8px;
    top: 50%;
    left: 50%;
    margin: -4px 0px 0px -4px;
}

.stagesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

// PAGE CONTENT

// STAGE 0

.helpWithContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 720px;
    height: fit-content;
    padding: 40px 20px 40px 20px;
    border-radius: 10px;
    background-color: white;
}

.helpWithContainer h2 {
    font-weight: normal;
    font-size: 16px;
    margin-top: 5px;
}

.helpWithItems {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 30px;
}

.helpWith {
    cursor: pointer;
    width: 220px;
    height: 150px;
    border: 2px solid var(--adfi_dark100);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    font-size: 15px;
    transition: scale(1.5);
}

.helpWith:hover {
    border: 2px solid var(--adfi_blue200);
}

.helpWithSelected {
    cursor: pointer;
    width: 220px;
    height: 150px;
    border: 2px solid var(--adfi_blue400);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    font-size: 15px;
}

.helpWithImg {
    max-width: 120px;
    max-height: 100px;
    padding: 10px;
}

// STAGE 2 + 3

.industryContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 720px;
    height: fit-content;
    padding: 40px 20px 40px 20px;
    border-radius: 10px;
    background-color: white;
}

.industryContainer h2 {
    font-weight: normal;
    font-size: 16px;
    margin-top: 5px;
}

.industryTypesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 30px;
    width: 100%;
}

.industryType {
    cursor: pointer;
    width: 100%;
    height: 56px;
    border: 2px solid var(--adfi_dark100);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
    font-size: 18px;
}

.industryType:hover {
    border: 2px solid var(--adfi_blue200);
}

.industryTypeSelected {
    cursor: pointer;
    width: 100%;
    height: 56px;
    border: 2px solid var(--adfi_blue400);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
    font-size: 18px;
}

// STAGE 4
.zipCodeContainer {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
}

.zipCodeContainer label {
    margin-left: 5px;
    margin-bottom: 5px;
}

.contactTypeContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.contactTypeContainer h2 {
    font-weight: 700;
    font-size: 22px;
    margin-top: 30px;
}

.contactType {
    display: flex;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    text-align: left;
}

.contactType:hover {
    background-color: var(--adfi_blue100);
}

.contactType input[type="checkbox"] {
    accent-color: var(--adfi_blue500);
}

//STAGE 5
.accountSizeGreyBox {
    height: 110px;
    width: 100%;
    background-color: #f2f3fb;
    grid-template-columns: 2fr 1fr;
    display: inline-grid;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 30px;
    border-radius: 8px;
}

.accountSizeBackgroundBar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 428px;
    height: 16px;
    border-radius: 8px;
    background-color: white;
    padding-left: 2px;
    padding-right: 2px;
}

.accountSizeStartSection {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue100);
    border-radius: 8px 0px 0px 8px;
}

.accountSizeStartSection:hover {
    background-color: var(--adfi_blue200);
}

.accountSizeSection {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue100);
}

.accountSizeSection:hover {
    background-color: var(--adfi_blue200);
}

.accountSizeEndSection {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue100);
    border-radius: 0px 8px 8px 0px;
}

.accountSizeEndSection:hover {
    background-color: var(--adfi_blue200);
}

.accountSizeStartSectionSelected {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue400);
    border-radius: 8px 0px 0px 8px;
}

.accountSizeSectionSelected {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue400);
}

.accountSizeEndSectionSelected {
    cursor: pointer;
    width: 50px;
    height: 10px;
    background-color: var(--adfi_blue400);
    border-radius: 0px 8px 8px 0px;
}

.assetRangeTextBox {
    border: 2px solid #81808d;
    border-radius: 6px;
    padding: 10px;
    background-color: white;
}

.assetRangeDashContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 428px;
    height: 16px;
    padding-left: 2px;
    padding-right: 2px;
    margin-left: 2px;
}

.assetRangeNumericalContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 428px;
    gap: 3px;
    height: 12px;
    text-align: center;
}

.rangeIndicator {
    width: 50px;
    height: 10px;
    border-right: 0.5px solid grey;
}

.blankRangeIndicator {
    width: 50px;
    height: 10px;
}

.assetRangeNumber {
    width: 50px;
    text-align: center;
}

.mobileAccountSizeDropdown {
    display: none;
}

.mobileBottomNav {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top: 2px solid var(--adfi_dark100);
}

.signInLink {
    color: var(--adfi_blue500);
    cursor: pointer;
    text-decoration: none;
}

.almostThereDisclosure {
    display: flex;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    text-align: left;
    align-items: flex-start;
}

.almostThereDisclosure:hover {
    background-color: var(--adfi_blue100);
}

.almostThereDisclosure input[type="checkbox"] {
    accent-color: var(--adfi_blue500);
}

.checkboxOffset {
    margin-top: 5px;
}

.loadingContainer {
    text-align: center;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.logo {
    width: 150px;
    margin-bottom: 20px;
}

.content {
    max-width: 400px;
    margin: 0 auto;
}

.progressBarLoading {
    width: 100%;
    height: 6px;
    background-color: #eee;
    border-radius: 3px;
    overflow: hidden;
    margin: 20px 0;
    position: relative;
}

.progressBarLoading::after {
    content: "";
    width: 0%;
    height: 100%;
    background: linear-gradient(271.17deg, #7315f9 0.01%, #d81afa 69.68%);
    position: absolute;
    left: 0;
    top: 0;
    animation: progress 10s forwards;
}

.loadingSubheader {
    color: var(--adfi_dark400);
    font-size: 14px;
}

@keyframes progress {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.checklist {
    list-style: none;
    padding: 0;
    margin: 20px 0 0 0;
    text-align: left;
}

.checkItem {
    display: flex;
    align-items: center;
    margin: 10px 0;
    position: relative;
    padding-left: 30px;
}

.checkItem::before {
    content: "○";
    font-size: 20px;
    color: #ccc;
    position: absolute;
    left: 0;
    transition: color 0.5s;
}

.checked {
    content: "✔";
}
.checked::before {
    content: "✔";
    color: var(--adfi_blue600);
}

@keyframes checkItems {
    0% {
        content: "○";
        color: var(--adfi_blue100);
    }
    16% {
        content: "○";
        color: var(--adfi_blue100);
    }
    32% {
        content: "○";
        color: var(--adfi_blue200);
    }
    48% {
        content: "○";
        color: var(--adfi_blue300);
    }
    64% {
        content: "○";
        color: var(--adfi_blue400);
    }
    80% {
        content: "✔";
        color: var(--adfi_blue500);
    }
    96% {
        content: "✔";
        color: var(--adfi_blue500);
    }
    100% {
        content: "✔";
        color: var(--adfi_blue600);
    }
}

.checkItem:nth-child(1) {
    animation: checkItems 6s forwards;
}
.checkItem:nth-child(2) {
    animation: checkItems 6s 1s forwards;
}
.checkItem:nth-child(3) {
    animation: checkItems 6s 2s forwards;
}
.checkItem:nth-child(4) {
    animation: checkItems 6s 3s forwards;
}
.checkItem:nth-child(5) {
    animation: checkItems 6s 4s forwards;
}
.checkItem:nth-child(6) {
    animation: checkItems 15s 5s forwards;
}

// ---- MOBILE SIZING ---- //

@media only screen and (max-width: 1024px) {
    .pageContainer {
        padding: 20px 0px 20px 0px;
    }

    .headerContainer {
        padding: 0px 10px 0px 10px;
    }

    .helpWithItems {
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
    }

    .helpWith {
        cursor: pointer;
        width: 100%;
        max-width: 450px;
        height: 60px;
        border: 2px solid var(--adfi_dark100);
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        justify-content: flex-start;
        padding: 10px;
        gap: 10px;
        font-size: 15px;
        transition: scale(1.5);
    }

    .helpWithSelected {
        cursor: pointer;
        width: 100%;
        max-width: 450px;
        height: 60px;
        border: 2px solid var(--adfi_blue400);
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        justify-content: flex-start;
        padding: 10px;
        gap: 10px;
        font-size: 15px;
    }

    .helpWithImg {
        max-width: 80px;
        max-height: 60px;
        padding: 10px;
    }

    .industryTypesContainer {
        display: flex;
        padding: 40px 10px 40px 10px;
        margin-top: 0px;
    }
}

@media only screen and (max-width: 800px) {
    .accountSizeGreyBox {
        height: 162px;
        width: 100%;
        background-color: #f2f3fb;
        display: inline-grid;
        grid-template-columns: 1fr;
        justify-content: space-evenly;
        align-items: center;
    }

    .accountRangeContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .accountSizeBackgroundBar {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 303px;
        height: 20px;
        border-radius: 8px;
        gap: 2px;
    }

    .accountSizeSection {
        width: 37px;
        height: 15px;
    }
    .accountSizeStartSection {
        width: 37px;
        height: 15px;
    }
    .accountSizeEndSection {
        width: 37px;
        height: 15px;
    }

    .accountSizeStartSectionSelected {
        width: 37px;
        height: 15px;
    }

    .accountSizeSectionSelected {
        width: 37px;
        height: 15px;
    }

    .accountSizeEndSectionSelected {
        width: 37px;
        height: 15px;
    }

    .assetRangeDashContainer {
        width: 303px;
        height: 16px;
        padding-left: 2px;
        padding-right: 2px;
        gap: 1px;
        margin-left: 2px;
    }

    .assetRangeNumericalContainer {
        width: 303px;
        height: 12px;
        text-align: center;
    }

    .assetRangeNumber {
        width: 35px;
        font-size: 13px;
        font-style: bold;
        text-align: center;
    }

    .assetRangeTextBox {
        text-align: center;
    }
}

@media only screen and (max-width: 1024px) {
    .helpWithContainer {
        margin-bottom: 100px;
    }

    .infoMessageContainer {
        margin-bottom: 100px;
    }
}
