.profPicFormContainer {
    margin-top: 30px;
    margin-bottom: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
}

.picturePreviewContainer {
    display: flex;
    flex-direction: column;
    min-width: 200px;
}

.picturePreviewImg {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-bottom: 25px;
    object-fit: cover;
}

.imgCropper {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.uploadImgText {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: var(--adfi_blue500);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
}

.pictureSmallPreviewContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 287px;
}

.pictuePreviewHeader {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_dark500);
}

.smallPicturesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.pupleBorder {
    border-radius: 50%;
    border: 0px solid;
    border-color: rgba(198, 174, 250, 1);
}

.gradientBorder {
    border: double 2px transparent;
    border-radius: 50%;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, rgba(115, 21, 249, 1), rgba(216, 26, 250, 1));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.smallPicture1 {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.smallPicture2 {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.smallPicture3 {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    object-fit: cover;
}

.modal-footer {
    border-top: 1px solid var(--adfi_dark50);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;
}

.modalButtons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.modal-cancel-button {
    height: 48px;
}

@media screen and (max-width: 1040px) {
    .imgCropper {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}
