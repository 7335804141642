.specialitiesContainer {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: var(--adfi_white);
    text-align: left;
    overflow: hidden;
}

.specialitiesHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: var(--adfi_dark500);
    gap: 10px;
}

.specialitiesHeader h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

.countNumber {
    background: var(--adfi_dark10);
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--adfi_dark300);
    padding: 4px 8px 4px 8px;
}

.submitOrderButton {
    padding: 3px 5px;
    font-size: 12px;
}

.addIcons {
    background: var(--adfi_dark10);
    border-radius: 25px;
    margin-left: auto;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
}

.showMoreArrow {
    cursor: pointer;
    transition: all 0.3s ease;

    &.open {
        transform: scaleY(-1);
    }
}

.dataContainer {
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
}

.clamp {
    max-height: 40px;
    row-gap: 30px;
}

.speciality {
    background: var(--adfi_white);
    border-radius: 6px;
    border-width: 1px;
    border-color: #7134f3;
    border-style: solid;
    padding: 8px 12px 8px 12px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.speciality:hover {
    background: var(--adfi_blue100);
}

.specialitySortable {
    background: var(--adfi_white);
    border-radius: 6px;
    border-width: 1px;
    border-color: #7134f3;
    border-style: solid;
    padding: 8px 12px 8px 12px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
}

.clientele {
    background: var(--adfi_dark10);
    border-radius: 6px;
    padding: 8px 12px 8px 12px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
}

.headerSubText {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

@media screen and (max-width: 1040px) {
    .specialitiesHeader {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .specialitiesHeader h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
}
