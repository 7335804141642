.linksContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-bottom: 10px;
}

.linksContainer a {
    text-decoration: none;
    color: black;
    font-size: 12px;
}

.backgroundGrey {
    background-color: var(--adfi_dark10);
}

.disclosureTextContainer {
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 20px;
}
