.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 40px;
}

.bannerContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 52px;
    background: var(--adfi_green50);
    border-radius: 6px;
    padding-left: 18px;
}

.bannerText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.completenessScoreInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    background: var(--adfi_dark10);
    border-radius: 16px;
    max-width: fit-content;
}

.percentagesContainer {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: var(--adfi_white);
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin-left: 12px;
    margin-top: 10px;
}

.top {
    border-radius: 8px 8px 0px 0px;
}

.bottom {
    border-radius: 0px 0px 8px 8px;
}

.twentyPercent {
    background-color: var(--adfi_blue700);
    padding: 10px 10px;
}

.tenPercent {
    background-color: #5b2ac3;
    padding: 10px 10px;
}

.fivePercent {
    background-color: #8e5df6;
    padding: 10px 10px;
}
