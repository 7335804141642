.pageContainer {
    display: flex;
    justify-content: center;
    background-color: var(--adfi_dark10);
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.blockScrolling {
    overflow: hidden;
    overscroll-behavior: contain;
}

.advisorCardsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
}

.contentContainer {
    margin: 0 30px 0 100px;
    width: 100vw;
    max-width: 1300px;
}

.advisorsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.searchInput {
    border: 1px solid var(--adfi_dark300);
    border-radius: 6px;
    padding: 15px;
    width: 400px;
    height: 40px;
}

.dashboardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 10px 0;
}

.dashboard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 5px;
    height: 100%;
}

.dashboard h1 {
    font-size: 1.5em;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 20px;
}

.mobileDashboard {
    display: none;
}

.displayNone {
    display: none;
}

.searchContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.mobileSearchContainer {
    display: none;
}

.searchBarWrapper {
    position: relative;
    display: flex;
    max-width: 580px;
    min-width: 300px;
}

.searchBar {
    border: 1px solid var(--adfi_dark200);
    border-radius: 5px;
    height: 46px;
    width: 100%;
    padding: 11px 44px 11px 44px;
    outline: 0;
    background: var(--adfi_white);
}

.searchBar:focus {
    border: 1px solid rgba(113, 52, 243, 1);
}

.searchIcon {
    position: absolute;
    top: 16px;
    left: 16px;
}

.cancelIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.optionRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_dark500);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.optionRow input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    display: inline-block;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: 1px solid var(--adfi_dark200);
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.optionRow:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.optionRow input:checked ~ .checkmark {
    background-color: rgba(113, 52, 243, 1);
    border: 1px solid var(--adfi_blue500);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.optionRow input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.optionRow .checkmark:after {
    margin-left: 4px;
    margin-top: 0px;
    width: 5px;
    height: 10px;
    border: 1.67px solid var(--adfi_white);
    border-width: 0 1.67px 1.67px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media only screen and (max-width: 1300px) {
    .filterContainer {
        display: none;
    }

    .gridContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 20px;
    }

    .contentContainer {
        margin: 0px;
        max-width: 1800px;
    }

    .searchContainer {
        display: none;
    }

    .dashboard h1 {
        font-size: 1.1rem;
    }

    .mobileDashboard {
        display: flex;
        gap: 10px;
        margin: 0px 5px 0px 5px;
    }

    .displayNone {
        display: none;
    }

    .mobileFilterLogo {
        padding: 10px;
    }

    .mobileFilterOverlay {
        z-index: 1999;
        position: fixed;
        top: 0;
        left: 0;
    }

    .mobileFilterContainer {
        width: 100%;
        height: 100%;
        position: fixed;
        overflow-x: hidden;
        overflow-y: scroll;
        touch-action: pan-y;
        background-color: white;
    }

    .mobileSearchContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--adfi_dark10);
        padding: 10px;
    }

    .dashboardContainer {
        margin: 0px 10px 0px 10px;
    }
}

@media only screen and (max-width: 850px) {
    .advisorCardsContainer {
        grid-template-columns: 1fr;
        gap: 10px;
    }
}
