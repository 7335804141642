.formContainer {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    // gap: 8px;
}

.optionRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    cursor: pointer;
    flex: 1 0 25%;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: var(--adfi_dark500);

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom: solid 1px var(--adfi_dark50);
}

/* Hide the browser's default checkbox */
.optionRow input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    display: inline-block;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: 1px solid var(--adfi_dark200);
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.optionRow:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.optionRow input:checked ~ .checkmark {
    background-color: rgba(113, 52, 243, 1);
    border: 1px solid var(--adfi_blue500);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.optionRow input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.optionRow .checkmark:after {
    margin-left: 4px;
    margin-top: 0px;
    width: 5px;
    height: 10px;
    border: 1.67px solid var(--adfi_white);
    border-width: 0 1.67px 1.67px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.modal-footer {
    border-top: 1px solid var(--adfi_dark50);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;
}

.modal-cancel-button {
    height: 48px;
}

.checkCircle {
    background-color: var(--adfi_green500);
    height: 36px;
    min-width: 36px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.checkCircleUnclicked {
    border: 1px solid #81808d;
    height: 36px;
    min-width: 36px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.sectionContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: left;
    background: #f2f3fb;
    padding: 10px;
    padding-left: 20px;
    align-items: center;
    gap: 6px;
    border-radius: 10px;
}

.inputLabel {
    font-weight: 700;
    font-size: 16px;
}

.input {
    width: fit-content !important;
}

//OLD CSS
.pageContainer {
    background: var(--adfi_dark10);
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    height: 100%;
}

.pageContainer li {
    font-size: 16px;
    margin-left: 20px;
}

.header {
    text-align: left;
}

.contentContainer {
    max-width: 550px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.questionHeader {
    font-size: 20px;
    font-weight: 700;
    color: #5b2ac3;
}

.pricingModelType {
    font-size: 16px;
    width: 200px;
    text-align: left;
}

.clienteleBox {
    border-radius: 6px;
    padding: 5px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    border: 1px solid var(--adfi_blue300);
    color: var(--adfi_blue500);
}

.comboInputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    margin-bottom: 20px;
    text-align: left;
}

.pricingModelInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.additionalInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;
    margin-left: 10px;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
}

@media only screen and (max-width: 1042px) {
    .pageContainer {
        padding: 100px 20px 20px 20px;
    }

    .header {
        font-size: 26px;
    }
}

@media only screen and (max-width: 800px) {
    .checkboxContainer {
        display: inline-grid;
        grid-template-columns: 2fr 2fr;
    }
}
