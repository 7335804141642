.page-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.register-container {
    z-index: 1;
    overflow-x: hidden;
}

.logo-container {
    text-align: left;
    padding: 20px;
    padding-bottom: 10px;
}

.picture-preview-img {
    width: 150px;
    max-width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 10px;
}

//Right-side content
.right {
    width: 100vw;
    height: 100vh;
    max-width: 100%;

    background: url("../../../public/images/half-screen-purple.png") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.right-side-text {
    color: #fff;
    text-align: left;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 75%;
    padding: 125px;
}

//Left-side content
.left {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
}

.form-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.form-container img {
    width: fit-content;
    max-height: 300px;
}

.form-container.apply {
    padding-top: 60px;
}

.register-form {
    width: 75%;
}

.register-header {
    font-size: 45px;
}

.register-paragraph {
    font-size: 20px;
}

.alt-login-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 5px 10px 5px 10px;
    margin: 15px;
    border-width: 1px;
    border-radius: 6px;
    border-style: solid;
}

.alt-login-button.img {
    padding-right: 10px;
}

.alt-login-text {
    width: 100%;
    height: 100%;
    padding: 10px 10px 10px 10px;
}

.line-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px;
}

.signup-line {
    color: grey;
    opacity: 50%;
}

.upload-image-container {
    background-color: var(--adfi_blue100);
    padding: 2px 5px 2px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--adfi_blue500);
    cursor: pointer;
}

//Inputs

.input-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.input-container.justify-left {
    justify-content: left;
}

.otp-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 30px;
}

.otp-input {
    width: 50px;
    height: 60px;
    border-width: 1px;
    border-radius: 6px;
    text-align: center;
    font-size: 20px;
}

.input-container.half-container {
    justify-content: space-between;
}

.input {
    border: 1px solid var(--adfi_dark300);
    border-radius: 6px;
    padding: 15px;
}

.input:focus {
    outline: none;
    border: 1px solid var(--adfi_blue300);
}

.input.full {
    width: 100%;
}

.input.half {
    width: 48%;
}

.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-top: 20px;
}

.password-spec-container {
    text-align: left;
    padding: 2px;
    padding-top: 5px;
}

//Errors
.error-message {
    text-align: left;
    color: red;
    padding: 2px;
    padding-bottom: 0px;
    display: flex;
    justify-content: left;
    align-items: left;
}

.forgotPasword {
    color: var(--adfi_blue500);
    cursor: pointer;
}

//Mid-size breakpoint
@media only screen and (max-width: 1400px) {
    .right {
        display: none;
    }

    .register-container {
        height: 100%;
        width: 100%;
        z-index: 1;
        overflow-x: hidden;
    }

    .form-container {
        overflow: hidden;
        padding: 30px;
        padding-top: 5px;
    }

    .password-spec-container {
        font-size: 15px;
    }
}

//mobile view
@media only screen and (max-width: 700px) {
    .form-container {
        padding: 20px;
        padding-top: 0px;
    }

    .logo-container {
        padding-bottom: 0px;
        text-align: center;
    }

    .form-container.apply {
        padding-top: 30px;
    }

    .input-container.half-container {
        padding-top: 20px;
    }

    .input-container.mobile-padding {
        padding-top: 10px;
    }

    .otp-input {
        width: 40px;
        height: 50px;

        text-align: center;
        font-size: 18px;
    }

    .input.half {
        width: 100%;
        margin-bottom: 20px;
    }

    .register-header {
        font-size: 30px;
    }

    .register-paragraph {
        font-size: 15px;
    }

    .password-spec-container {
        font-size: 12px;
        padding: 2px;
    }
}

.optionRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-top: 20px;
    cursor: pointer;
    flex: 1 0 25%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_dark500);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.optionRow input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    display: inline-block;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: 1px solid var(--adfi_dark200);
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.optionRow:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.optionRow input:checked ~ .checkmark {
    background-color: rgba(113, 52, 243, 1);
    border: 1px solid var(--adfi_blue500);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.optionRow input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.optionRow .checkmark:after {
    margin-left: 4px;
    margin-top: 0px;
    width: 5px;
    height: 10px;
    border: 1.67px solid var(--adfi_white);
    border-width: 0 1.67px 1.67px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.feedbackContainer {
    border: 1.67px solid var(--adfi_dark500);
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 10px;

    padding: 20px;
}

.feedbackOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    font-size: 20px;
    padding: 5px;
    border: 1.67px solid var(--adfi_dark300);
    cursor: pointer;
}
