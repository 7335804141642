.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
}

.formContainer h1 {
    text-align: center;
    font-size: 22px;
}

.greenInfoContainer {
    background-color: #f2f7f4;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border-radius: 8px;
}
.greenInfoContainer svg {
    color: #51c88e;
    flex-shrink: 0;
}

.infoSection {
    margin-bottom: 20px;
}

.infoSection h2 {
    font-size: 16px;
}

.infoSection h3 {
    font-size: 16px;
}

.infoSection h4 {
    font-size: 16px;
}

.modalFooter {
    background-color: var(--adfi_dark50);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 30px;
}

.footerScrollButtons {
    display: flex;
    gap: 5px;
}

.pricingModelContainer {
    display: flex;
    flex-direction: row;
    gap: 14px;
}

.checkCircle {
    background-color: var(--adfi_green500);
    height: 36px;
    min-width: 36px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.pricingTextContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--adfi_dark100);
    width: 100%;
}

.pricingTextValue {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

.pricingTextDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}
