.input {
    border: 1px solid var(--adfi_dark300);
    border-radius: 6px;
    padding: 15px;
    width: 100%;
    position: relative;
}

.input:focus {
    outline: none;
    border: 1px solid var(--adfi_blue300);
}

.inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.errorMessage {
    text-align: left;
    color: red;
    margin-top: 15px;
}

.hasError {
    outline: 1px solid red;
}

.heightDefault {
    height: 40px;
}

.heightXS {
    height: 20px;
}

.heightS {
    height: 30px;
}

.heightL {
    height: 50px;
}

.heightXL {
    height: 60px;
}
