// Side Nav (App view)

.display-none {
    display: none;
}

.profilePicture {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.gradientBorder {
    border: double 2px transparent;
    border-radius: 50%;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, rgba(115, 21, 249, 1), rgba(216, 26, 250, 1));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.pupleBorder {
    border-radius: 50%;
    border: 0px solid;
    border-color: rgba(198, 174, 250, 1);
}

.navIconSpacer {
    margin-right: 5px;
}

@media only screen and (min-width: 1301px) {
    .navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: fixed;
        height: 100vh;
        width: 70px;
        left: 1;
        z-index: 10000;
        background-color: #fff;
        font-weight: 700;
        transition-duration: 250ms;
        transition-property: width;
        overflow: hidden;
        margin-top: 50px;
        //box-shadow: inset 1px 5px 12px -6px rgb(216, 216, 216);
        box-shadow:
            inset 1px 2px 4px hsl(220deg 0% 85% / 0.333),
            inset 2px 4px 6px hsl(220deg 0% 85% / 0.333);
    }

    .navbar:hover {
        width: 180px;
    }

    .nav-button {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 15px;
        width: 50px;
        height: 50px;
        border-radius: 6px;
        text-decoration: none !important;
        color: var(--adfi_dark500);
    }

    .nav-button.open {
        gap: 10px;
        width: 150px;
        padding-left: 15px;
        justify-content: left;
    }

    .nav-button.none {
        display: none;
    }

    .nav-button.expander {
        margin-top: 175px;
    }

    .nav-button:hover {
        background-color: rgb(233, 233, 233);
        color: var(--adfi_blue500);
        cursor: default;
        -webkit-transform: translate(0px, -2px);
        -ms-transform: translate(0px, -2px);
        transform: translate(0px, -2px);
        width: 150px;
        padding-left: 15px;
        justify-content: left;
    }
}

@media only screen and (min-width: 1301px) {
    .hamburger-menu {
        display: none;
    }

    .hamburger-links {
        display: none;
    }

    .ham-nav-logo {
        display: none;
    }
}

// Hamburger Menu (mobile view)

@media only screen and (max-width: 1300px) {
    .ham-nav-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 76px;
        width: 100%;
        position: sticky;
        top: 0px;
        z-index: 1000;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: #fff;
        box-shadow: 0 4px 6px 0 rgb(36 34 52 / 6%);
        font-weight: 700;
    }

    .ham-nav-logo {
        margin-left: 1rem;
    }

    .hamburger-menu {
        display: flex;
    }

    .hamburger-links {
        display: flex;
        flex-direction: column;
        position: fixed;
        padding: 20px;
        top: 80px;
        right: 0;
        gap: 14px;
        background-color: #fff;
        box-shadow: 1px 6px 6px 1px rgba(2, 2, 2, 0.2);
        border-radius: 10px;
        border-top: 2px;
        list-style-type: none;
        text-align: left;
        margin-right: 10px;
        z-index: 200000001;
    }

    .ham-link-line {
        border: 1px solid var(--adfi_blue500);
    }

    .ham-link a {
        text-decoration: none;
        margin-bottom: 5px;
        font-size: 20px;
        color: var(--adfi_blue500);
    }

    .ham-link a:hover {
        color: #8661d5;
    }
}

@media only screen and (max-width: 1300px) {
    .navbar {
        display: none;
    }

    .nav-button {
        display: none;
    }
}

//Top nav css

@media only screen and (min-width: 1301px) {
    .top-nav {
        height: 50px;
        width: 100%;
        position: fixed;
        top: 0px;
        z-index: 10000;
        background-color: #fff;
        box-shadow:
            1px 2px 2px hsl(220deg 20% 85% / 0.333),
            2px 4px 4px hsl(220deg 20% 85% / 0.333);
        font-weight: 700;
    }
    .top-nav img {
        position: absolute;
        top: 7px;
        left: 15px;
        cursor: pointer;
    }
    .top-nav-links {
        display: flex;
        flex-direction: row;
        gap: 50px;
        justify-content: center;
        align-items: center;
        height: 50px;
    }

    .top-nav-links a {
        cursor: pointer;
        text-decoration: none;
        color: black;
    }

    .vertical-page-offset {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 1300px) {
    .top-nav {
        display: none;
    }

    .vertical-page-offset {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 1041px) {
    .top-nav {
        display: none;
    }

    .vertical-page-offset {
        margin-top: 0px;
    }
    .ham-nav-bar {
        position: fixed;
    }
}
