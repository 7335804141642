.dashboardContainer {
    display: flex;
    flex-direction: column;
    background: var(--adfi_dark10);
    min-height: 100vh;
}

.sideNavMargin {
    padding-left: 0px;
}

@media only screen and (min-width: 1200px) {
    .sideNavMargin {
        padding-left: 70px;
    }
}

.statisticsContainer {
    margin-left: 35px;
}

.infoContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: 35px;
    margin-right: 30px;
}

.meetingRequestContainer {
    background-color: var(--adfi_white);
    width: 50%;
    padding: 30px 20px 30px 20px;
    border-radius: 8px;
}

.meetingHeader {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: var(--adfi_dark500);
    justify-content: flex-start;
    align-items: center;
    display: flex;
    gap: 10px;
    padding-left: 12px;
    margin-bottom: 16px;
}

.timeSort {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--adfi_dark300);
    align-items: center;
    display: flex;
    margin-left: auto;
}

.timeSortButton {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    align-items: center;
    display: flex;
    gap: 2px;
}

.profileHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 25px;
    margin-bottom: 29px;
    margin-left: 35px;
}

.profileHeaderText {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: var(--adfi_dark500);
    margin-left: 14px;
    margin-right: 33px;
}

.rightSideButtons {
    margin-left: auto;
    margin-right: 30px;
    display: flex;
    gap: 16px;
}

.meetingRequest {
    display: flex;
    flex-direction: row;
    background: #f2f3fb;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 6px;
}

.meetingInfo {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
}

.consumerName {
    font-weight: 700;
    font-size: 14px;
    text-align: left;
    line-height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.rectangle {
    width: 10px;
    height: 10px;
    background: var(--adfi_blue500);
    border-radius: 3px;
}

.meetingTime {
    margin-left: auto;
}

.scroll {
    overflow-y: auto;
    height: 400px;
    padding-right: 9px;
}

@media screen and (max-width: 1040px) {
    .dashboardContainer {
        flex-direction: column;
        width: 100vw;
        gap: 1px;
    }

    .profileHeader {
        margin-left: 20px;
    }

    .profileHeaderText {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
    }

    .infoContainer {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        gap: 1px;
    }

    .meetingRequestContainer {
        width: 100%;
    }

    .scroll {
        height: auto;
    }
}
