.App {
    text-align: center;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

button,
html input[type="button"],
input[type="reset"] {
    border: 0;
    cursor: pointer;
    -webkit-appearance: button;
}

img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

// global CSS Vars

:root {
    // colors
    --adfi_white: #ffffff;
    --adfi_dark10: #f2f3fb;
    --adfi_dark50: #e9eaf5;
    --adfi_blue100_30percent: rgba(227, 214, 253, 0.3);
    --adfi_blue50: #f1ebfe;
    --adfi_dark800_30percent: rgba(18, 17, 26, 0.3);
    --adfi_dark900_30percent: rgba(36, 34, 52, 0.9);

    --adfi_red500: #ef5d49;
    --adfi_red50: #ffecea;
    --adfi_green500: #51c88e;
    --adfi_green50: #f2f7f4;
    --adfi_yellow500: #ffbe00;
    --adfi_yellow100: #fff3d1;

    --adfi_blue100: #e3d6fd;
    --adfi_blue200: #c6aefa;
    --adfi_blue300: #aa85f8;
    --adfi_blue400: #8e5df6;
    --adfi_blue500: #7134f3;
    --adfi_blue600: #5b2ac3;
    --adfi_blue700: #441f92;
    --adfi_blue800: #2d1561;
    --adfi_blue900: #170a31;

    --adfi_pink100: #f7d1fe;
    --adfi_pink200: #efa3fd;
    --adfi_pink300: #e876fc;
    --adfi_pink400: #e048fb;
    --adfi_pink500: #d81afa;
    --adfi_pink600: #ad15c8;
    --adfi_pink700: #821096;
    --adfi_pink800: #560a64;
    --adfi_pink900: #2b0532;

    --adfi_dark100: #d5d5d9;
    --adfi_dark200: #abaab3;
    --adfi_dark300: #81808d;
    --adfi_dark400: #575567;
    --adfi_dark500: #2d2b41;
    --adfi_dark600: #242234;
    --adfi_dark700: #1b1a27;
    --adfi_dark800: #12111a;
    --adfi_dark900: #09090d;

    --adfi_gradients_main: linear-gradient(56deg, #7315f9 1.98%, #d81afa 78.61%);
    --adfi_gradients_main_inverted: linear-gradient(271.17deg, #7315f9 0.01%, #d81afa 69.68%);
    --adfi_gradients_horizontal: linear-gradient(56deg, #7315f9 1.98%, #d81afa 78.61%);
    --adfi_gradients_main_hover: linear-gradient(56deg, #7315f9 1.98%, #d81afa 36.7%, #e062f6 78.61%);
    --adfi_gradients_main_click: linear-gradient(56deg, #7315f9 32.71%, #d81afa 78.61%);
}

// Text styles

.adfi-bold {
    font-weight: 700 !important;
}

.adfi-text-12 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.adfi-text-14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.adfi-text-16 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.adfi-text-h5 {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
}

.adfi-text-h4 {
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
}

.adfi-text-h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
}

.adfi-text-h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
}

.adfi-text-h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 47px;
}

.adfi-text-banner {
    font-weight: 700;
    font-size: 48px;
    line-height: 50px;
}

.spinnerContainer {
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}

.react-datepicker {
    height: 50vh !important;
}

.react-datepicker__month-container {
    overflow: hidden;
    height: 50vh;
}

.react-datepicker__header {
    background-color: var(--adfi_white) !important;
    border-bottom: none !important;
}

.react-datepicker__day-names {
    background: #f2f3fb;
    margin-top: 8px;
}

.react-datepicker__navigation-icon::before {
    border-color: var(--adfi_blue300) !important;
}
