.dropdown {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    padding-left: 15px;
    border: 1px solid var(--adfi_dark200);

    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat white;
    background-position: calc(100% - 0.95rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
}

.dropdown:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}

.dropdown:focus {
    outline: none;
    border: 1px solid var(--adfi_blue300);
}

.dropdown::selection {
    margin-left: 5px;
    color: var(--adfi_dark100);
}

.errorMessage {
    text-align: left;
    color: red;
    margin-top: 15px;
}

.heightDefault {
    height: 40px;
}

.heightXS {
    height: 20px;
}

.heightS {
    height: 30px;
}

.heightL {
    height: 50px;
}

.heightXL {
    height: 60px;
}
