.servicesContainer_edit {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: var(--adfi_white);
    text-align: left;
    overflow: hidden;
    border-radius: 0px 0px 8px 8px;
}

.servicesHeader_edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: var(--adfi_dark500);
    gap: 10px;
}

.servicesHeader_edit h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

.countNumber {
    background: var(--adfi_dark10);
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--adfi_dark300);
    padding: 4px 8px 4px 8px;
}

.addIcons {
    background: var(--adfi_dark10);
    border-radius: 25px;
    margin-left: auto;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
}

.showMoreArrow {
    cursor: pointer;
    transition: all 0.3s ease;

    &.open {
        transform: scaleY(-1);
    }
}

.dataContainer_edit {
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
}

.editPencil {
    position: absolute;
    right: 4.05%;
    top: 9.05%;
    bottom: 8.34%;
    cursor: pointer;
}

.clamp {
    max-height: 96px;
    row-gap: 30px;
}

.educationContainer {
    position: relative;
    background: var(--adfi_white);
    border-radius: 6px;
    border: 1px solid #e9eaf5;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 8px;
    height: 96px;
    width: 100%;
}

.pointer {
    cursor: pointer;
}

.serviceText_edit {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
}

.serviceName {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
}

.serviceImg {
    width: 96px;
    height: 100%;
    align-items: center;
    display: flex;
    max-height: 100%;
}

@media screen and (max-width: 1040px) {
    .servicesHeader_edit {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        border-radius: 0;
    }

    .servicesHeader_edit h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .dataContainer_edit {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 6px;
    }

    .educationContainer {
        width: 100%;
        max-width: 100%;
        align-items: center;
        flex-direction: row;
    }

    .serviceText_edit {
        align-items: flex-start;
        gap: 6px;
    }

    .editPencil {
        right: 4.05%;
        top: 23.05%;
    }

    .clamp {
        max-height: 369px;
        row-gap: 6px;
    }
}
