.pageContainer {
    display: flex;
    justify-content: center;
    background-color: var(--adfi_dark10);
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.blockScrolling {
    overflow: hidden;
    overscroll-behavior: contain;
}

.contentContainer {
    margin: 0 30px 0 100px;
    width: 100vw;
    max-width: 1400px;
}

.greyLineDivider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--adfi_dark200);
    opacity: 50%;
    margin-bottom: 10px;
    margin-top: 10px;
}

.advisorsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media only screen and (max-width: 1300px) and (min-width: 1100px) {
    .advisorsContainer {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.searchInput {
    border: 1px solid var(--adfi_dark300);
    border-radius: 6px;
    padding: 15px;
    width: 400px;
    height: 40px;
}

.dashboardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 10px 0;
}

.dashboard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 5px;
}

.dashboard h1 {
    font-size: 1.5em;
}

.gridContainer {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.mobileDashboard {
    display: none;
}

.displayNone {
    display: none;
}

.searchContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.mobileSearchContainer {
    display: none;
}

.searchBarWrapper {
    position: relative;
    display: flex;
    max-width: 580px;
    min-width: 300px;
}

.searchBar {
    border: 1px solid var(--adfi_dark200);
    border-radius: 5px;
    height: 50px;
    width: 100%;
    padding: 11px 44px 11px 44px;
    outline: 0;
    background: var(--adfi_white);
}

.searchBar:focus {
    border: 1px solid rgba(113, 52, 243, 1);
}

.searchIcon {
    position: absolute;
    top: 16px;
    left: 16px;
}

.cancelIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.noResults {
    font-size: 20px;
    margin-top: 50px;
}

@media only screen and (max-width: 1300px) {
    .filterContainer {
        display: none;
    }

    .gridContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 20px;
    }

    .contentContainer {
        margin: 0px;
        max-width: 1800px;
    }

    .searchContainer {
        display: none;
    }

    .dashboard h1 {
        font-size: 1.1rem;
    }

    .mobileDashboard {
        display: flex;
        gap: 10px;
        margin: 0px 5px 0px 5px;
    }

    .displayNone {
        display: none;
    }

    .mobileFilterLogo {
        padding: 10px;
    }

    .mobileFilterOverlay {
        z-index: 1999;
        position: fixed;
        top: 0;
        left: 0;
    }

    .mobileFilterContainer {
        width: 100%;
        height: 100%;
        position: fixed;
        overflow-x: hidden;
        overflow-y: scroll;
        touch-action: pan-y;
        background-color: white;
    }

    .mobileSearchContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--adfi_dark10);
        padding: 10px;
    }

    .dashboardContainer {
        margin: 0px 10px 0px 10px;
    }
}

//Current Filters

.currentFiltersContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.currentFilter {
    cursor: default;
    width: fit-content;
    height: 45px;
    border: 2px solid var(--adfi_blue200);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
    font-size: 18px;
}

.xButton {
    cursor: pointer;
    color: var(--adfi_blue300);
}
.xButton:hover {
    cursor: pointer;
    color: var(--adfi_blue500);
}

@media only screen and (max-width: 800px) {
    .currentFiltersContainer {
        display: flex;
        flex-wrap: wrap;
        margin-left: 5px;
        margin-right: 5px;
    }

    .currentFilter {
        cursor: default;
        width: fit-content;
        height: 45px;
        border: 2px solid var(--adfi_blue200);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px;
        gap: 5px;
        font-size: 12px;
    }

    .dashboardContainer {
        justify-content: center;
    }
}

//No results

.noResultsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.verticalOffsetMedium {
    margin-bottom: 25px;
}

.noResultsBulletContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.noResultsBullet {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 10px;
    text-align: left;
}

.noResultsBulletArrow {
    color: #abaab3;
}

@media only screen and (max-width: 800px) {
    .noResultsBullet {
        gap: 10px;
    }

    .noResultsContainer {
        padding: 10px;
    }
}

@media only screen and (max-width: 1040px) {
    .dashboardContainer {
        padding: 100px 0px 10px 0px;
    }
}
