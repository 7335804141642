.pageContainer {
    background: var(--adfi_dark10);
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}

.myPageContainer {
    background: var(--adfi_dark10);
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}

.sideNavMargin {
    padding-left: 0px;
}

.myProfileContainer {
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
    width: 100%;
    max-width: 1400px;
    padding-left: 30px;
    padding-right: 30px;
    gap: 20px;
}

.profileContainer {
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
    width: 100%;
    max-width: 1400px;
    padding-left: 30px;
    padding-right: 30px;
    gap: 20px;
}

.profileHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    margin-bottom: 30px;
    width: 100%;
    max-width: 1400px;
    padding-left: 35px;
    padding-right: 35px;
}

.myProfileHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    margin-bottom: 30px;
    width: 100%;
    max-width: 1400px;
    padding-left: 35px;
    padding-right: 35px;
}

.iconContainer svg {
    min-width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileTextContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.rightSideButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.profileHeaderText {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: var(--adfi_dark500);
    margin-left: 14px;
    margin-right: 33px;
    text-align: left;
}

.editDisclosure {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 10px 20px 10px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    gap: 10px;
    height: 66px;

    background: var(--adfi_red50);
    border-radius: 8px;

    font-weight: 700;
    font-size: 1.1vw;
    line-height: 26px;
    color: var(--adfi_red500);
}

.profileToggle {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    display: flex;
    margin: 3px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.toggleWrapper {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 170px;
    height: 44px;
    padding: 0px;
    background: var(--adfi_white);
    border-radius: 30px;
    -o-object-fit: fill;
    object-fit: fill;
}

.togglePurple {
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: 0%;
    width: 82px;
    height: 38px;
    border-radius: 30px;
    background-color: var(--adfi_blue500);
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.toggleText {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: all 400ms;
}

.toggleTextHover:hover {
    background: var(--adfi_blue100);
    border-radius: 30px;
}

.completenessScoreContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1400px;
    width: 100%;
}

.completenessScoreBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #e9eaf5;
    height: 64px;
    max-width: 1400px;
    padding: 20px 20px 20px 30px;
    gap: 10px;
    border-radius: 8px;
    max-width: 1400px;
    width: 100%;
}

.mobileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.mobileContainerText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.completenessScoreNumber {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_blue500);
}

.completenessScoreText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.progressBarContainer {
    padding: 4px;
    width: 123px;
    height: 14px;
    background: var(--adfi_white);
    border-radius: 10px;
}

.progressBar {
    height: 14px;
    height: 6px;
    background: linear-gradient(56deg, #7315f9 1.98%, #d81afa 78.61%);
    border-radius: 30px;
}

.howItWorksContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    color: var(--adfi_blue500);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-left: auto;
    cursor: pointer;
}

.footerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1400px;
    padding-left: 30px;
    padding-right: 30px;
}

.lastUpdatedDate {
    display: flex;
    align-items: flex-start;
    text-align: left;
    margin-top: 25px;
    margin-bottom: 25px;
}

@media only screen and (min-width: 1300px) {
    .sideNavMargin {
        padding-left: 70px;
    }
}

// @media only screen and (max-width: 1300px) and (min-width: 1041px) {
//     .profileContainer {
//         margin-left: 20px;
//         padding-top: 20px;
//     }

//     .profileHeader {
//         margin-left: 10px;
//     }
// }

@media screen and (max-width: 1040px) {
    .pageContainer {
        margin-top: 76px;
    }

    .myPageContainer {
        margin-top: 166px;
    }

    .profileContainer {
        flex-direction: column;
        width: 100vw;
        gap: 1px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .myProfileContainer {
        flex-direction: column;
        width: 100vw;
        gap: 1px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .profileHeader {
        margin-left: 20px;
        display: flex;
        justify-content: center;
        position: fixed;
        padding: 20px;
        margin: 0;
        top: 76px;
        background-color: var(--adfi_dark10);
        z-index: 100;
    }

    .myProfileHeader {
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        position: fixed;
        padding: 20px;
        margin: 0;
        top: 76px;
        background-color: var(--adfi_dark10);
        z-index: 100;
    }

    .profileHeaderText {
        font-weight: 700;
        font-size: 1.8vmax;
        line-height: 26px;
    }

    .toggleWrapper {
        width: 84px;
        margin-left: auto;
    }

    .togglePurple {
        width: 40px;
        border-radius: 40px;
    }

    .editDisclosure {
        padding: 20px 10px 20px 10px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 2.54vw;
        margin-bottom: 0px;
    }

    .completenessScoreContainer {
        padding: 12px 16px 16px 16px;
        gap: 10px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
    }

    .completenessScoreNumber {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
    }

    .completenessScoreText {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }

    .mobileContainer {
        flex-direction: column;
        align-items: flex-start;
    }

    .howItWorksContainer {
        font-size: 12px;
    }

    .disclosureContainer {
        padding-left: 16px;
        padding-right: 16px;
    }

    .rsnipDisclosureContainer {
        padding-left: 16px;
        padding-right: 16px;
    }

    .toggleWrapper {
        margin-left: 0;
    }
}

@media screen and (max-width: 355px) {
    .scheduleButtonText {
        display: none;
    }
}
