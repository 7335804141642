.searchBarWrapper {
    position: relative;
    display: flex;
    max-width: 580px;
    margin-top: 20px;
}

.searchBar {
    border: 1px solid var(--adfi_dark200);
    border-radius: 5px;
    height: 46px;
    width: 100%;
    padding: 11px 44px 11px 44px;
    outline: 0;
    background: var(--adfi_white);
}

.searchBar:focus {
    border: 1px solid rgba(113, 52, 243, 1);
}

.searchIcon {
    position: absolute;
    top: 16px;
    left: 16px;
}

.cancelIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    // visibility: hidden;
}

.dropDownWrapper {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background: var(--adfi_white);
    box-shadow: 0px 8px 20px rgb(45 43 65 / 20%);
    border-radius: 6px;
    padding: 6px;
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 200;
}

.option {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_dark500);
    padding: 11px 24px 11px 24px;
    border-radius: 5px;
    cursor: pointer;
}

.option:hover {
    background: var(--adfi_blue100_30percent);
}

.selectedServiceContainer {
    background: var(--adfi_dark10);
    border: 1px solid var(--adfi_dark50);
    border-radius: 6px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 16px 11px 16px;
}

.clearIcon {
    margin-left: auto;
    cursor: pointer;
}
