.formContainer {
    display: flex;
    flex-direction: column;
    min-height: 220px;
    gap: 15px;
}

.bannerText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.searchBarWrapper {
    position: relative;
    display: flex;
    max-width: 580px;
}

.searchBar {
    border: 1px solid var(--adfi_dark200);
    border-radius: 5px;
    height: 46px;
    width: 100%;
    padding: 11px 44px 11px 44px;
    outline: 0;
    background: var(--adfi_white);
}

.searchBar:focus {
    border: 1px solid rgba(113, 52, 243, 1);
}

.searchIcon {
    position: absolute;
    top: 16px;
    left: 16px;
}

.cancelIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    // visibility: hidden;
}

.dropDownWrapper {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background: var(--adfi_white);
    box-shadow: 0px 8px 20px rgb(45 43 65 / 20%);
    border-radius: 6px;
    padding: 6px;
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 200;
}

.option {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_dark500);
    padding: 11px 24px 11px 24px;
    border-radius: 5px;
    cursor: pointer;
}

.option:hover {
    background: var(--adfi_blue100_30percent);
}

.sectionWrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.serviceImg {
    min-width: 92px;
    min-height: 92px;
    max-width: 92px;
    max-height: 92px;
    border: 1px solid var(--adfi_dark50);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.speciality {
    background: var(--adfi_dark10);
    border-radius: 6px;
    padding: 8px 12px 8px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_dark500);
}

.selectedServiceContainer {
    background: var(--adfi_dark10);
    border: 1px solid var(--adfi_dark50);
    border-radius: 6px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 16px 11px 16px;
}

.selectDateContainer {
    background: #ffffff;
    border: 1px solid var(--adfi_dark200);
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 16px 11px 16px;
    gap: 10px;
}

.datePicker {
    font-family: "Assistant" !important;
    font-style: normal;
}

.datePicker__react-datepicker__navigation {
    border: 1.67px solid #aa85f8;
}

.yearPicker {
    border: 1px solid var(--adfi_dark50);
    border-radius: 6px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 16px 11px 16px;
}

.clearIcon {
    margin-left: auto;
    cursor: pointer;
}

.introForm {
    width: 100%;
    margin-bottom: 30px;
}

.infoTextArea {
    width: 100%;
    height: 94px;
    padding: 11px 16px;
    box-sizing: border-box;
    border: 1px solid var(--adfi_dark300);
    border-radius: 4px;
    background-color: #ffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    resize: none;
}

.characterCount {
    color: var(--adfi_dark300);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
}

.modal-footer {
    border-top: 1px solid var(--adfi_dark50);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;
}

.removeButton {
    height: 48px;
    border: 1px solid var(--adfi_red500);
    color: var(--adfi_red500);
}

.removeButton:hover:not([disabled]) {
    color: var(--adfi_red500);
}

.modalButtons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}
