.servicesContainer_edit {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: var(--adfi_white);
    text-align: left;
    overflow: hidden;
}

.servicesHeader_edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: var(--adfi_dark500);
    gap: 10px;
}

.servicesHeader_edit h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

.countNumber {
    background: var(--adfi_dark10);
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--adfi_dark300);
    padding: 4px 8px 4px 8px;
}

.addIcons {
    background: var(--adfi_dark10);
    border-radius: 25px;
    margin-left: auto;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
}

.submitOrderButton {
    padding: 3px 5px;
    font-size: 12px;
}

.showMoreArrow {
    cursor: pointer;
    transition: all 0.3s ease;

    &.open {
        transform: scaleY(-1);
    }
}

.dataContainer_edit {
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
}

.editPencil {
    position: absolute;
    right: 9.05%;
    top: 9.05%;
    bottom: 8.34%;
    cursor: pointer;
    z-index: 10;
}

.dragHandle {
    position: absolute;
    left: 3.05%;
    top: 1.05%;
    bottom: 8.34%;
    display: flex;
    height: 51px;
    width: 12px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    touch-action: none;
    cursor: var(--cursor, pointer);
    border-radius: 5px;
    border: none;
    outline: none;
    appearance: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}

.dragHandle:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.dragHandle:focus-visible {
    box-shadow: 0 0px 0px 2px #4c9ffe;
}

.dragHandle svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: #919eab;
}

.clamp {
    max-height: 203px;
    row-gap: 30px;
}

.service_edit {
    position: relative;
    background: var(--adfi_white);
    border-radius: 6px;
    border: 1px solid #e9eaf5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px 20px 20px;
    gap: 12px;
    max-width: 192px;
    max-height: 206px;
    overflow: hidden;
}

.service_edit:hover {
    background-position: 10% 0%;
    background-size: auto;
    -webkit-transform: translate(0px, -2px);
    -ms-transform: translate(0px, -2px);
    transform: translate(0px, -2px);
    border: 1px solid var(--adfi_blue500);
}

.serviceText_edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.serviceName {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.serviceDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.servicesContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.servicesHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.headerTextContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.headerText {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

.headerText h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

.headerSubText {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.headerButtonContainer {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: 4px;
}

.servicesExpander {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--adfi_blue500);
    gap: 5px;
}

.headerButton {
    width: 32px;
    height: 32px;
    background: #e9eaf5;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
}

.dataContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.service {
    background: var(--adfi_white);
    border-radius: 6px;
    border: 1px solid #e9eaf5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px 20px 20px;
    gap: 12px;
    width: 48%;
}

.service:hover {
    background-position: 10% 0%;
    background-size: auto;
    -webkit-transform: translate(0px, -2px);
    -ms-transform: translate(0px, -2px);
    transform: translate(0px, -2px);
    border: 1px solid var(--adfi_blue500);
}

.moreButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--adfi_blue500);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    gap: 4px;
    cursor: pointer;
}

.serviceImg {
    width: fit-content;
    height: fit-content;
    max-height: 40px;
    max-width: 40px;
}

@media screen and (max-width: 1040px) {
    .dragHandle {
        position: initial;
    }
    .servicesHeader_edit {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .servicesHeader_edit h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .dataContainer_edit {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 6px;
    }

    .service_edit {
        max-width: 100%;
        align-items: center;
        flex-direction: row;
    }

    .serviceText_edit {
        align-items: flex-start;
        gap: 6px;
    }

    .editPencil {
        right: 4.05%;
        top: 23.05%;
        z-index: 10;
    }

    .clamp {
        max-height: 369px;
        row-gap: 6px;
    }

    .serviceDescription {
        text-align: left;
    }

    .servicesContainer {
        padding-left: 16px;
        padding-right: 16px;
        width: 100vw;
        max-width: 100vw;
    }

    .dataContainer {
        gap: 6px;
        flex-direction: column;
    }
}
