.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.allSetContentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.listHeader {
    font-size: 25px;
}

.bottomBorder {
    border-bottom: 2px solid #d5d5d9;
}
