.serviceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 30px 30px 30px;
    gap: 20px;
}

.serviceDescription {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}
