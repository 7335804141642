.intoFormContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal-footer {
    border-top: 1px solid var(--adfi_dark50);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;
}

.modal-cancel-button {
    height: 48px;
}
