.intoFormContainer {
    display: flex;
    flex-direction: column;
}

.bannerContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 52px;
    background: var(--adfi_green50);
    border-radius: 6px;
    padding-left: 18px;
    margin-bottom: 30px;
}

.bannerText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.introForm {
    width: 100%;
}

.infoTextArea {
    width: 100%;
    height: 238px;
    padding: 11px 16px;
    box-sizing: border-box;
    border: 1px solid var(--adfi_dark300);
    border-radius: 4px;
    background-color: #ffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    resize: none;
}

.characterCount {
    color: var(--adfi_dark300);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
}

.rsnipTextArea {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    border: 1px solid;
    padding: 10px;
}

.rsnipTextArea ul {
    padding-left: 20px;
}

.modal-footer {
    border-top: 1px solid var(--adfi_dark50);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
}

.modal-cancel-button {
    height: 48px;
}

.optionRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding-bottom: 30px;
    cursor: pointer;
    flex: 1 0 25%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_dark500);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.optionRow input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    display: inline-block;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: 1px solid var(--adfi_dark200);
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.optionRow:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.optionRow input:checked ~ .checkmark {
    background-color: rgba(113, 52, 243, 1);
    border: 1px solid var(--adfi_blue500);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.optionRow input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.optionRow .checkmark:after {
    margin-left: 4px;
    margin-top: 0px;
    width: 5px;
    height: 10px;
    border: 1.67px solid var(--adfi_white);
    border-width: 0 1.67px 1.67px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
