.rightPanelContainer {
    display: flex;
    flex-direction: column;
    min-width: 400px;
    max-width: 400px;
}

.pricingModelsContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    margin-top: 31px;
    max-width: 428px;
}

.pricingModelsHeader {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: var(--adfi_dark500);
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 13px;
}

.pricingModelsHeader h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

.assetRangesHeader {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: var(--adfi_dark500);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 13px;
}

.assetRangesText {
    display: flex;
    align-items: center;
    gap: 18px;
}

.modelsContainer {
    background: var(--adfi_white);
    border-radius: 8px 8px 8px 8px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
}

.model {
    background: var(--adfi_white);
    border-radius: 6px;
    border-style: solid;
    border-color: #7134f3;
    border-width: 1px;
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
    padding: 10px;
    flex-basis: 48%;
}

.model:hover {
    background: var(--adfi_blue100);
}

.pricingModel {
    background: var(--adfi_dark10);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
    padding: 10px;
    flex-basis: 48%;
}

.pricingModelModalButton {
    color: var(--adfi_blue400);
    cursor: pointer;
}

.headerSubText {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.miniProfileContainer {
    /* Advisor Finder/White */
    border-radius: 8px 8px 0px 0px;
    background: var(--adfi_white);
    margin-top: 31px;
    padding: 20px;
}

.miniProfileAdvisorInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.profilePicContainer {
    max-height: 180px;
    flex-shrink: 0;
}

.headShotImg {
    width: 100px;
    height: 100px;
}

.picturePreviewImg {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.profileInfoContainer {
    align-items: flex-start;
    text-align: left;
    margin-left: 20px;
}

.locationText {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    /* Advisor Finder/Dark/500 */
    color: var(--adfi_dark500);
    display: inline;
}

.advisorNameText {
    font-weight: 700;
    font-size: 18px;
    line-height: 31px;
    color: var(--adfi_dark500);
    margin-top: 2px;
}

.purpleText {
    display: flex;
    flex-direction: row;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: var(--adfi_blue500);
    align-items: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    gap: 7px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.jobTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.employerText {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
}

.miniProfileScheduleButton {
    margin-top: 20px;
}

.gradientBorder {
    border: double 2px transparent;
    border-radius: 50%;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, rgba(115, 21, 249, 1), rgba(216, 26, 250, 1));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.pupleBorder {
    border-radius: 50%;
    border: 0px solid;
    border-color: rgba(198, 174, 250, 1);
}

@media screen and (max-width: 1040px) {
    .pricingModelsContainer {
        padding-left: 16px;
        padding-right: 16px;
        width: 100vw;
        max-width: 100vw;
    }

    .miniProfileContainer {
        width: 100vw;
        max-width: 100vw;
    }
}
