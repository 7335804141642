.purpleText {
    color: var(--adfi_blue500);
    align-items: center;
    cursor: pointer;
}

.calendlyForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: auto;
    margin-left: 78px;
    margin-top: 40px;
    gap: 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: 75%;
}

.buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    gap: 10px;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contactTypeContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}

.contactTypeContainer h2 {
    font-weight: 700;
    font-size: 22px;
    margin-top: 30px;
}

.contactType {
    display: flex;
    gap: 10px;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    text-align: left;
    width: 100%;
}

.contactType:hover {
    background-color: var(--adfi_blue100);
}

.contactType input[type="checkbox"] {
    accent-color: var(--adfi_blue500);
}
