.advisorCardContainer {
    background-color: white;
    border-radius: 8px;
    padding: 20px 20px 20px 20px;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    color: var(--adfi_dark500);
    box-shadow:
        1px 2px 2px hsl(220deg 20% 85% / 0.333),
        2px 4px 4px hsl(220deg 20% 85% / 0.333),
        3px 6px 6px hsl(220deg 20% 85% / 0.333);
    border: 1px solid transparent; /* <- here */
    transition: border 0.5s;
    text-decoration: none; /* I added this for clarity of effect */
}

.advisorCardContainer:hover {
    border: 1px solid var(--adfi_blue300);
}

.profilePictureContainer {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
}

.profilePicture {
    width: 132px;
    height: 132px;
    margin-right: 25px;
    object-fit: cover;
}

.gradientBorder {
    border: double 2px transparent;
    border-radius: 50%;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, rgba(115, 21, 249, 1), rgba(216, 26, 250, 1));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.pupleBorder {
    border-radius: 50%;
    border: 0px solid;
    border-color: rgba(198, 174, 250, 1);
}

.textSpacing {
    margin-bottom: 10px;
}

.advisorInfoContainer {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 10px;
}

.subInfo {
    font-size: 0.83em;
    font-weight: normal;
}

.advisorInfo {
    text-align: left;
}

.location {
    color: var(--adfi_dark400);
    font-size: 0.83em;
    font-weight: normal;
}

.verified {
    color: var(--adfi_blue500);
    background-color: var(--adfi_blue50);
    border-radius: 6px;
    padding: 0px 4px 0px 4px;
    width: fit-content;
}

.occupationText {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 5px;
    color: var(--adfi_blue500);
}

.bottomMargin {
    margin-bottom: 10px;
}

.tagsHeader {
    text-align: left;
    font-size: 15px;
    margin-bottom: 5px;
    margin-left: 5px;
}

.tagsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

.tag {
    border-radius: 6px;
    padding: 5px;
    width: fit-content;
    font-size: 14px;
    border: 1px solid var(--adfi_blue300);
    color: var(--adfi_blue500);
}

.footerContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    text-align: left;
}

.footerSection {
    font-size: 15px;
    margin: 5px;
}

.footerSection ul {
    margin-left: 16px;
}

.submitActivateButton {
    font-size: 12px;
    color: var(--adfi_dark600);
}

.activateContainer {
    margin-left: auto;
    margin-bottom: auto;
}

.cardCTAContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.cardCTAContainerMobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 1100px) {
    .advisorCardContainer {
        border-radius: 0px;
    }

    .advisorCardContainer:hover {
        border: none;
    }
}
