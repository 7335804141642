.advisorsHeaderContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
}

.numberOfProfessionals {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.advisorsSort {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.distanceSort {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    gap: 5px;
}

.distanceSortCheck {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.advisorCardsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
}

.blockScrolling {
    overflow: hidden;
    overscroll-behavior: contain;
}

/* Hide the browser's default checkbox */
.optionRow input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    display: inline-block;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid var(--adfi_dark200);
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.optionRow:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.optionRow input:checked ~ .checkmark {
    background-color: rgba(113, 52, 243, 1);
    border: 1px solid var(--adfi_blue500);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: relative;
    display: none;
}

/* Show the checkmark when checked */
.optionRow input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.optionRow .checkmark:after {
    margin-left: 6px;
    margin-top: 2px;
    width: 5px;
    height: 10px;
    border: 1.67px solid var(--adfi_white);
    border-width: 0 1.67px 1.67px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media only screen and (max-width: 1100px) {
    .advisorCardsContainer {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
    }
}
