.intoFormContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-bottom: 40px;
}

.profileContainer {
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: center;
    padding: 20px;
    gap: 20px;
    border: 1px solid #e9eaf5;
    border-radius: 8px;
}

.profileInfoContainer {
    align-items: flex-start;
    text-align: left;
}

.profilePicture {
    width: 75px;
    height: 75px;
    margin-bottom: 5px;
    object-fit: cover;
}

.gradientBorder {
    border: double 2px transparent;
    border-radius: 50%;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, rgba(115, 21, 249, 1), rgba(216, 26, 250, 1));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.pupleBorder {
    border-radius: 50%;
    border: 0px solid;
    border-color: rgba(198, 174, 250, 1);
}

.locationText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* Advisor Finder/Dark/500 */
    color: var(--adfi_dark500);
    display: inline;
}

.advisorNameText {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_dark500);
}

.verifiedAdFi {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    gap: 7px;
    background: var(--adfi_blue50);
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* Advisor Finder/Blue/500 */
    color: var(--adfi_blue500);
}

.shareText {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
}

.linkBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 11px 16px 11px 16px;
    gap: 10px;
    border: 1px solid #abaab3;
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 100%;
    overflow: scroll;
}

.socialMediaContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.shareButton {
    cursor: pointer;
}

.shareButton:hover:not(:active) {
    opacity: 0.75;
}

.modal-footer {
    border-top: 1px solid var(--adfi_dark50);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;
}

.modal-cancel-button {
    height: 48px;
}
