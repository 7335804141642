.carousel-container {
    padding: 125px;
}

.carousel-container h2 {
    font-size: 2em;
}

.carousel {
    display: flex;
    flex-direction: column;
    justify-content: first baseline;
    align-items: flex-start;
    background-color: white;
    text-align: left;
    width: 100%;
    border-radius: 12px;
    height: 200px;
    padding: 20px;
}

.carousel.h2 {
    color: #fff;
}

//Buttons
.carousel-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 20px;
}

.circle {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: transform 0.2s;
}

.circle.active {
    background-color: white;
    transform: scale(1.2);
}

.circle.activePuprle {
    background-color: var(--adfi_blue500);
    transform: scale(1.2);
}
