.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
}

.formContainer h1 {
    text-align: center;
    font-size: 22px;
}

.serviceImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.serviceImg img {
    height: 75px;
    width: 75px;
}

.serviceName {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
}

.serviceDescription {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
}

.quckFactContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    min-height: 52px;
    width: 100%;
    border-radius: 6px;
    padding-left: 18px;
    background: var(--adfi_green50);
}

.detailsContainer {
    border: 1px solid #e9eaf5;
    border-radius: 8px;
    width: 100%;
    padding: 20px;
}

.descriptionHeader {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

///

.greenInfoContainer {
    background-color: #f2f7f4;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border-radius: 8px;
}
.greenInfoContainer svg {
    color: #51c88e;
    flex-shrink: 0;
}

.infoSection {
    margin-bottom: 20px;
}

.infoSection h2 {
    font-size: 16px;
}

.infoSection h3 {
    font-size: 16px;
}

.infoSection h4 {
    font-size: 16px;
}

.modalFooter {
    background-color: var(--adfi_dark50);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 30px;
}

.footerScrollButtons {
    display: flex;
    gap: 5px;
}
