.quickFacts {
    border-radius: 8px 8px 8px 8px;
    padding: 15px 30px 15px 30px;
    display: flex;
    flex-direction: column;
    max-width: 428px;
    background: var(--adfi_white);
    text-align: left;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.header {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: left;
    color: var(--adfi_dark500);
}

.addDetails {
    cursor: pointer;
    padding: 5px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--adfi_blue500);
    border-radius: 8px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: var(--adfi_blue500);
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    background-color: transparent;
}

.userName {
    color: var(--adfi_blue500);
}

.factsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.factContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.factText {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--adfi_dark500);
    margin-top: 10px;
}

.fact {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;
}

@media screen and (max-width: 1040px) {
    .quickFacts {
        max-width: 100%;
    }

    .header {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .experienceContainer {
        align-items: center;
        flex-direction: row;
        border-right: none;
    }

    .distanceContainer {
        align-items: center;
        flex-direction: row;
        border-right: none;
        padding-right: 0;
        padding-left: 0;
    }

    .memberContainer {
        align-items: center;
        flex-direction: row;
        border-right: none;
        padding-right: 0;
        padding-left: 0;
    }

    .factText {
        margin-top: 0;
        width: 140px;
    }

    .fact {
        margin-top: 0;
    }
}
