.profileContainer {
    display: flex;
    flex-direction: column;
    /* Advisor Finder/White */
    gap: 1px;
    // padding-top: 20px;
    flex-grow: 1;
}

.profileHeadshotContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    /* Advisor Finder/White */
    border-radius: 8px 8px 0px 0px;
    background: var(--adfi_white);
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.headshotInfoContainer {
    display: flex;
    flex-direction: row;
}

.profilePicContainer {
    max-height: 180px;
    flex-shrink: 0;
}

.headShotImg {
    width: 180px;
    height: 180px;
}

.picturePreviewImg {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    object-fit: cover;
}

.imgOverlay {
    position: relative;
    bottom: 100%;
    width: 180px;
    height: 180px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    background: rgba(115, 21, 249, 0.8);
    cursor: pointer;
}

.advisorVerifiedSocials {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.importantDisclosureText {
    font-size: 14px;
}

.verifiedByAdfi {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    border: 1px solid;
    border-radius: 8px;
    padding: 15px;
    border-color: var(--adfi_dark100);
    cursor: pointer;
}

.verifiedByAdfi:hover {
    background: var(--adfi_blue100);
    border-color: #7134f3;
}

.socialMediaButtonsContainer {
    border: 1px solid;
    border-radius: 8px;
    border-color: var(--adfi_dark100);
    padding: 15px;
    gap: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.twitterButton {
    border-radius: 100%;
    background-color: black;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkedInButton {
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialMediaButton {
    border-radius: 100%;
    background-color: var(--adfi_blue500);
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialLogo {
    max-width: fit-content;
    max-height: 20px;
    cursor: pointer;
}

.socialLogoLinkedIn {
    max-width: fit-content;
    max-height: 30px;
    cursor: pointer;
}

.gradientBorder {
    border: double 2px transparent;
    border-radius: 50%;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, rgba(115, 21, 249, 1), rgba(216, 26, 250, 1));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.pupleBorder {
    border-radius: 50%;
    border: 0px solid;
    border-color: rgba(198, 174, 250, 1);
}

.cursorPointer {
    cursor: pointer;
}

.profileInfoContainer {
    align-items: flex-start;
    text-align: left;
    margin-left: 20px;
}

.locationText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    /* Advisor Finder/Dark/500 */
    color: var(--adfi_dark500);
    display: inline;
}

.advisorNameText {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: var(--adfi_dark500);
    margin-top: 2px;
}

.purpleText {
    display: flex;
    flex-direction: row;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_blue500);
    align-items: center;
    flex: none;
    order: 1;
    flex-grow: 0;
    gap: 7px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.jobTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.employerText {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.ratingBox {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 212px;
    height: 98px;
    background: var(--adfi_white);
    border: 1px solid var(--adfi_dark50);
    border-radius: 6px;
    padding: 12px 0px;
    gap: 6px;
    margin-left: auto;
}

.verifiedAdFi {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    gap: 7px;
    background: var(--adfi_blue50);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    /* Advisor Finder/Blue/500 */
    color: var(--adfi_blue500);
}

.advisorIntroContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 24px;
    /* Advisor Finder/White */
    background: var(--adfi_white);
    border-radius: 0px;
    text-align: left;
}

.advisorIntroTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    /* Advisor Finder/Dark/500 */
    color: var(--adfi_dark500);
}

.bioContainer {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.videoContainer {
    margin-left: auto;
    order: 2;
    display: flex;
    max-height: 240px;
    min-width: 240px;
}

.addVideoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 245px;
    max-height: 104px;
    gap: 20px;
    border: 1px solid #e9eaf5;
    border-radius: 6px;
    padding: 5px;
    text-align: center;
}

.addVideoContainerText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: var(--adfi_blue500);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
}

.advisorIntroText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--adfi_dark500);
    white-space: pre-line;
}

.clamp {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}

.showMoreArrow {
    cursor: pointer;
    transition: all 0.3s ease;

    &.open {
        transform: scaleY(-1);
    }
}

.headerSubText {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.showMore {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--adfi_blue500);
    cursor: pointer;
}

@media screen and (max-width: 1250px) {
    .profileHeadshotContainer {
        flex-direction: column;
        align-items: flex-start;
        border-radius: 0;
        padding-bottom: 0;
    }

    .advisorVerifiedSocials {
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
        margin-bottom: 30px;
    }

    .verifiedByAdfi {
        margin-right: 0px;
        margin-left: 0px;
        padding: 15px;
        font-size: 11px;
        margin-bottom: 0px;
        height: 45px;
    }

    .socialMediaButtonsContainer {
        margin-right: 0px;
        margin-left: 0px;
        padding: 15px;
        font-size: 9px;
        margin-bottom: 0px;
        height: 45px;
    }

    .verifiedByAdfi svg {
        width: 15;
        height: 15;
    }

    .importantDisclosureText {
        font-size: 12px;
    }
}

@media screen and (max-width: 1040px) {
    .profileHeadshotContainer {
        flex-direction: column;
        align-items: flex-start;
        border-radius: 0;
        padding-bottom: 0;
    }

    .ratingBox {
        flex-direction: row;
        border: none;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 72px;
        margin-top: 16px;
        border-top: solid;
        border-radius: 0;
        border-color: var(--adfi_dark10);
    }

    .profilePicContainer {
        max-height: 110px;
    }

    .picturePreviewImg {
        width: 110px;
        height: 110px;
        object-fit: cover;
    }

    .imgOverlay {
        width: 110px;
        height: 110px;
    }

    .advisorIntroTitle {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .purpleText {
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 12px;
        line-height: 16px;
    }

    .jobTitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }

    .employerText {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 10px;
    }

    .verifiedAdFi {
        font-size: 12px;
        line-height: 16px;
    }

    .ratingContainer {
        flex-direction: row-reverse;
    }

    .bioContainer {
        flex-direction: column;
    }
}

@media only screen and (max-width: 1300px) and (min-width: 1041px) {
    .profileHeadshotContainer {
        border-radius: 8px 8px 0px 0px;
    }
}

@media screen and (max-width: 1041px) {
    .verifiedByAdfi {
        margin-right: 0px;
        margin-left: 0px;
        padding: 15px;
        font-size: 9px;
        margin-bottom: 0px;
        height: 45px;
    }

    .verifiedByAdfi svg {
        width: 15;
        height: 15;
    }

    // .advisorVerifiedSocials {
    //     margin-bottom: 15px;
    // }
}

@media screen and (max-width: 600px) {
    .profileHeadshotContainer {
        flex-direction: column;
        padding-bottom: 0;
    }

    .headshotInfoContainer {
        margin-bottom: 10px;
    }

    .socialMediaButtonsContainer {
        margin-right: 0px;
        margin-left: 0px;
        padding: 10px;
        max-height: 45px;
    }

    .profilePicContainer {
        max-height: 110px;
    }

    .picturePreviewImg {
        width: 130px;
        height: 130px;
        object-fit: cover;
    }

    .imgOverlay {
        width: 130px;
        height: 130px;
        bottom: 118%;
    }

    .advisorVerifiedSocials {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }

    .socialLogo {
        max-width: fit-content;
        max-height: 15px;
        cursor: pointer;
    }

    .socialLogoLinkedIn {
        max-width: fit-content;
        max-height: 30px;
        cursor: pointer;
    }
}
