.textArea {
    width: 100%;
    height: 100px;
    padding: 8px 10px;
    box-sizing: border-box;
    border: 1px solid var(--adfi_dark300);
    border-radius: 4px;
    background-color: #ffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    resize: none;
}

.textAreaCharacterCount {
    font-size: 12px;
    font-weight: normal;
}

.textArea:focus {
    outline: none;
    border: 1px solid var(--adfi_blue300);
}

.errorMessage {
    text-align: left;
    color: red;
}

.hasError {
    border: 1px solid red;
}

.heightDefault {
    height: 100px;
}

.heightXS {
    height: 60px;
}

.heightS {
    height: 80px;
}

.heightL {
    height: 120px;
}

.heightXL {
    height: 140px;
}
